<template>
  <div class="w-full flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded">
    <div class="space-y-1 text-center">
      <FontAwesomeIcon
        size="2x"
        class="text-primary dark:text-white"
        :icon="faFile"
      />
      <div class="text-center">
        <p class="text-gray-600 mb-1">
          {{ file.name }}
        </p>
        <button
          type="button"
          class="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          @click="$emit('removeFile', file)"
        >
          Löschen
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { faFile } from '@fortawesome/pro-duotone-svg-icons'

export default {
  props: {
    file: {
      type: Object,
      required: true
    }
  },
  emits: {
    removeFile: {
      type: Object
    }
  },
  data() {
    return {
      faFile
    }
  }

}
</script>
