<template>
  <IonPage>
    <IonContent
      v-if="tenant"
      class="ion-padding"
    >
      <div class="flex justify-center">
        <img
          v-if="tenant && tenant.logo"
          class="logo mt-12"
          :src="tenant.logo.downloadUrl"
          alt=""
        >
        <img
          v-if="tenant && !tenant.logo"
          class="logo dark:hidden  mt-12"
          src="@/assets/mydocuments-logo.svg"
          alt=""
        >
        <img
          v-if="tenant && !tenant.logo"
          class="logo hidden dark:block  mt-12"
          src="@/assets/mydocuments-logo-white.svg"
          alt=""
        >
      </div>
      <div v-if="tenant && tenant.welcomeText">
        <div
          class="leading-7 mt-4"
          v-html="tenant.welcomeText"
        />
      </div>
      <div class="grid grid-cols-2 gap-6 mt-8">
        <a
          v-if="tenant.phone"
          class="btn py-3 bg-gray-100 text-primary dark:bg-primary dark:text-white w-full"
          :href="`tel:+${tenant.phone}`"
          :class="{ 'col-span-1': tenant.email, 'col-span-2': !tenant.email }"
        >
          <FontAwesomeIcon
            size="lg"
            :icon="faPhone"
          />
        </a>
        <a
          v-if="tenant.email"
          class="btn py-3 bg-gray-100 text-primary dark:bg-primary dark:text-white w-full"
          :href="`mailto:${tenant.email}`"
          :class="{ 'col-span-1': tenant.phone, 'col-span-2': !tenant.phone }"
        >
          <FontAwesomeIcon
            size="lg"
            :icon="faEnvelope"
          />
        </a>
      </div>
      <div v-if="tenant && tenant.impressumText">
        <h4 class="mt-10">
          Impressum:
        </h4>
        <div v-html="tenant.impressumText" />
      </div>
      <div v-if="tenant && tenant.agbText">
        <h4 class="mt-10">
          AGB:
        </h4>
        <div v-html="tenant.agbText" />
      </div>
    </IonContent>
  </IonPage>
</template>

<script>
import { faPhone, faEnvelope } from '@fortawesome/pro-solid-svg-icons'
import { IonPage, IonContent } from '@ionic/vue'

export default  {
  components: {
    IonContent,
    IonPage,
  },
  data: () => ({
    faPhone,
    faEnvelope,
    loading: true,
    tenant: null,
  }),
  computed: {
    user(){
      return this.$store.getters['auth/user']
    },
  },
  watch: {
    '$route': {
      handler: 'fetchData',
      immediate: true
    }
  },
  methods: {
    async fetchData() {
      this.loading = true
      const tenantId = this.user.tenantId
      this.tenant = await this.$store.dispatch('tenants/get', tenantId)
      this.loading = false
    },
  }
}
</script>
