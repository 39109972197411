<template>
  <IonPage>
    <IonHeader />
    <IonContent
      :fullscreen="true"
      class="ion-padding"
    >
      <div class="text-center">
        <h2 class="mt-20">
          Passwort ändern
        </h2>
        <h5 class="text-gray-400 mb-4 mx-8 leading-6">
          Geben Sie ein neues Passwort ein.
        </h5>
      </div>
      <form
        class="mt-8"
        @submit.prevent="changePassword"
      >
        <div class="mt-3 mb-4">
          <BasicInput
            id="password"
            type="password"
            name="password"
            class="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded dark:bg-gray-800 dark:border-gray-800"
            placeholder="***"
            rule="required"
            label-class="block font-medium text-gray-500 dark:text-gray-500"
            label="Passwort"
          />
        </div>
        <div class="mt-3">
          <BasicInput
            id="confirmpassword"
            type="password"
            name="confirmpassword"
            class="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded dark:bg-gray-800 dark:border-gray-800"
            placeholder="***"
            rule="required"
            label="Passwort wiederholen"
            label-class="block font-medium text-gray-500 dark:text-gray-500"
          />
        </div>
        <div class="text-center mt-4">
          <button
            id="submit"
            type="submit"
            class="btn bg-primary text-white px-12"
          >
            Passwort ändern
          </button>
        </div>
      </form>
    </IonContent>
  </IonPage>
</template>

<script>
import { IonPage, IonContent, IonHeader } from '@ionic/vue'
import BasicInput from '../../components/BasicInput.vue'
export default {
  components: {
    IonPage,
    IonContent,
    IonHeader,
    BasicInput
  }
}
</script>
