<template>
  <div>
    <div class="flex">
      <input
        :id="id"
        v-model="model"
        type="checkbox"
        class="checkbox"
        :class="inputClass"
        :disabled="disabled"
      >
      <label
        v-if="label || labelText"
        :for="id"
      >{{ label }}
        <slot name="labelText" />
      </label>
    </div>
    <BasicValidationFeedback :error-message="error" />
  </div>
</template>

<script>
import useValidateModel from '@/composables/useValidateModel.js'
import BasicValidationFeedback from './components/BasicValidationFeedback.vue'
export default {
  components: {
    BasicValidationFeedback,
  },
  props: {
    // Custom Props für dieses BasicInput
    disabled: {
      type: Boolean,
      default: false
    },
    inputClass: {
      type: String,
      default: null
    },
    // Standard Props bei allen BasicInputs
    modelValue: {
      type: null,
      default: null
    },
    label: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    rules: {
      required: false,
      type: [ Object,String, Function ],
      default: () => { return {} }
    },
    labelText: {
      type: Boolean,
      default: false
    },
    customErrorMessage: {
      type: String,
      default: null
    }
  },
  // model composable bei allen BasicInputs
  emits: [ 'update:modelValue' ],
  setup(props, { emit }){
    const { model, errorMessage } = useValidateModel(props, emit)
    return {
      model,
      errorMessage
    }
  },
  computed: {
    error(){
      if (!this.customErrorMessage) return this.errorMessage
      if (this.errorMessage) return this.customErrorMessage
      return ''
    }
  }
}
</script>
