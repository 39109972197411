<template>
  <div>
    <div
      class="content-wrapper"
      :class="{ 'open': !collapsed }"
      :style="!collapsed ? `max-height: ${height}px;` : ''"
    >
      <div ref="heightElement">
        <slot />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    collapsed: {
      default: true,
      type: Boolean
    }
  },
  data: () => ({
    loading: true,
    height: 1000
  }),
  mounted() {
    this.height = this.$refs.heightElement ? this.$refs.heightElement.offsetHeight : 1000
  },
  updated() {
    this.height = this.$refs.heightElement ? this.$refs.heightElement.offsetHeight : 1000
  }
}
</script>
<style lang="scss" scoped>
.content-wrapper{
  overflow: hidden;
  max-height: 0;
  transition: max-height 300ms ease;
  &.open{
    transition: max-height 300ms ease;
  }
}
</style>