<template>
  <IonPage>
    <IonHeader class="ion-no-border">
      <IonToolbar mode="ios">
        <IonButtons slot="start">
          <IonBackButton
            mode="md"
            class="text-primary dark:text-white"
          />
        </IonButtons>
        <IonTitle>
          Neue Nachricht
        </IonTitle>
      </IonToolbar>
    </IonHeader>
    <IonContent>
      <Form @submit="submit">
        <div class="ion-padding">
          <div class="mt-4">
            <div class="mt-1">
              <BasicInput
                id="title"
                v-model="title"
                label-class="block text-sm font-medium text-gray-700 dark:text-gray-500"
                label="Betreff"
                name="title"
                type="text"
                rules="required"
              />
            </div>
          </div>
          <div class="mt-4">
            <div class="mt-1">
              <BasicInput
                id="message"
                v-model="message"
                as="textarea"
                name="message"
                label="Ihre Nachricht"
                label-class="block text-sm font-medium text-gray-700 dark:text-gray-500"
                rules="required"
              />
            </div>
          </div>
          <div class="mt-4">
            <file-upload
              v-if="!file"
              @upload="upload"
            />
            <file-preview
              v-if="file"
              :file="file"
              @removeFile="file = null"
            />
          </div>
          <input
            type="submit"
            value="Nachricht absenden"
            class="mt-4 btn btn-primary w-full"
          >
        </div>
      </Form>
    </IonContent>
  </IonPage>
</template>
<script>
import { IonPage, IonContent, IonHeader, IonToolbar, IonButtons, IonBackButton, IonTitle, toastController, alertController, loadingController } from '@ionic/vue'
import FileUpload from '../../components/FileUpload.vue'
import BasicInput from '../../components/BasicInput.vue'
import FilePreview from '../../components/FilePreview.vue'
import { Form } from 'vee-validate'
export default {
  components: {
    IonPage,
    IonContent,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonTitle,
    FileUpload,
    BasicInput,
    FilePreview,
    Form
  },
  data: () => ({
    title: '',
    message: '',
    file: null
  }),
  methods: {
    async submit() {
      const loading = await loadingController.create({
        message: 'Nachricht senden'
      })
      await loading.present()
      try {
        let fileData = this.file ? {
          fileData: {
            name: this.file.name,
            type: this.file.type,
            size: this.file.size,
            file: this.file
          }
        } : null
        await this.$store.dispatch('process-add/create', {
          contractId: this.$route.params.id,
          title: this.title,
          message: this.message,
          ...fileData
        })
        this.openToast()
        this.$router.go(-1)
      } catch (err) {
        const alert = await alertController.create({
          header: 'Fehler',
          message: err.message,
          buttons: [ 'Okay' ]
        })
        alert.present()
      } finally {
        loading.dismiss()
      }
    },
    async upload(event) {
      let files = event.target.files
      if (files.length === 0) return

      this.file = files[0]
    },
    async openToast(){
      const toast = await toastController
        .create({
          message: 'Nachricht gesendet',
          duration: 4000,
          mode: 'ios',
          cssClass: 'transform -translate-y-16',
        })
      return toast.present()
    },
  }
}
</script>
