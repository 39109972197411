import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { IonicVue } from '@ionic/vue'
import Vue from 'vue'
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { FeathersVuex } from './feathers-client'

import './theme/tailwind.scss'
import './theme/main.scss'
import './registerServiceWorker'

if (process.env['VUE_APP_SENTRY'] === 'true') {
  Sentry.init({
    Vue,
    dsn: 'https://7573660809f24c22b630a6449e2a02db@o74848.ingest.sentry.io/5641677',
    integrations: [ new Integrations.BrowserTracing() ],

    tracesSampleRate: 1.0,
  })
  Sentry.setTag('version', process.env['VUE_APP_VERSION'])
}

const version = process.env.VUE_APP_VERSION || 'x.x.x'
store.commit('setVersion', version)

import './plugins/vee-validate/vee-validate.js'


const app = createApp(App)
  .use(IonicVue)
  .use(store)
  .use(FeathersVuex)
  .use(router)

router.isReady().then(() => {
  app.mount('#app')
})

app.component('FontAwesomeIcon', FontAwesomeIcon)
