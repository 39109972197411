import feathersClient, { makeServicePlugin, BaseModel } from '../../feathers-client'

class AssfinetContractKinds extends BaseModel {
  constructor(data, options) {
    super(data, options)
  }

  static instanceDefaults() {
    return {}
  }
}

AssfinetContractKinds.modelName = 'AssfinetContractKinds'

const servicePath = 'assfinet-contract-kinds'
const servicePlugin = makeServicePlugin({
  idField: 'Id',
  Model: AssfinetContractKinds,
  service: feathersClient.service(servicePath),
  servicePath
})

feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
})

export default servicePlugin
