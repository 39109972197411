<template>
  <div class="mt-4">
    <BasicInput
      id="bauTyp"
      v-model="localValue.bauTyp"
      name="bauTyp"
      as="select"
      rules="required"
      label="Betroffene Sache"
    >
      <option
        value="0"
        disabled
        selected
      >
        bitte wählen
      </option>
      <option value="1">
        Maschinen
      </option>
      <option value="2">
        Maschinen-BU
      </option>
      <option value="3">
        Elektronik
      </option>
      <option value="4">
        Bauleistung
      </option>
      <option value="5">
        Montage
      </option>
    </BasicInput>
  </div>
</template>
<script>
import { reactive, toRef, watch } from 'vue'
import BasicInput from '../../../components/BasicInput.vue'

export default {
  components: { BasicInput },
  props: {
    modelValue: {
      type: Object,
      default: () => {}
    }
  },
  emits: [ 'update:modelValue' ],
  setup(props, { emit }) {
    const modelValue = toRef(props, 'modelValue')
    const localValue = reactive({ ...modelValue.value })

    watch(() => localValue, (val) => emit('update:modelValue', val), { deep: true })

    return {
      localValue
    }
  }
}
</script>
