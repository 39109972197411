import { Plugins } from '@capacitor/core'
import * as Sentry from '@sentry/vue'
import store from '@/store/'
import router from '@/router/'

let failed = false
let success = false
let lastBackground = null
let wasInBackground = true

const publicPageNames = [
  'Login',
  'ForgetPassword',
  'ChangePassword'
]

Plugins.App.addListener('appStateChange', async (state) => {
  const isLoggedIn = store.getters['auth/isAuthenticated']

  if (!isLoggedIn) return

  const user = store.getters['auth/user']
  const tenantId = user.tenantId
  const tenant = store.getters['tenants/get'](tenantId)

  if (!tenant.app || !tenant.app.biometric || !Plugins.NativeBiometric) return
  try {
    const result = await Plugins.NativeBiometric.isAvailable()
    if (!result) return
    const isAvailable = result.isAvailable
    if (!isAvailable) return
    if (!state.isActive) {
      success = false
      lastBackground = new Date()
      wasInBackground = true
    } else if (!success) {
      await router.push({ name: 'Login', query: { skipWelcome: true } })
      await router.go(-1)
    }

  } catch (error) {
    console.error('appStateChange', error)
    Sentry.captureException(error)
  }
})


export default async function biometricGuard(to, from, next) {
  const isLoggedIn = store.getters['auth/isAuthenticated']

  if (!isLoggedIn) {
    return next()
  }

  let toPublicPage = publicPageNames.includes(to.name)
  if (toPublicPage) return next()

  const user = store.getters['auth/user']
  const tenantId = user.tenantId
  const tenant = store.getters['tenants/get'](tenantId)

  if (!tenant.app || !tenant.app.biometric || !Plugins.NativeBiometric) return next()

  if ((lastBackground && (new Date() - lastBackground) < 5 * 60 * 1000) || !wasInBackground) {
    return next()
  }

  try {
    const result = await Plugins.NativeBiometric.isAvailable()
    if (!result) return next()
    const isAvailable = result.isAvailable

    if (!isAvailable) return next()

    if (isAvailable && !failed && !success) {
      await Plugins.NativeBiometric.verifyIdentity({
        reason: 'für das sichere Anmelden',
        title: 'Anmelden'
      })
      wasInBackground = false
      success = true
      return next()
    }
  } catch (error) {
    if (error !== 'NativeBiometric does not have web implementation.' ) {
      if (error.errorMessage  && error.errorMessage !== 'Error: Canceled by user.') {
        console.error('Biometric guard is disabled')
        next()
      } else {
        console.error(`nativeError: "${error}"`)
        console.error(error)
        Sentry.captureException(error)
        failed = true
      }
    } else {
      next()
    }
  }
}
