<template>
  <IonPage>
    <IonHeader class="ion-no-border" />
    <IonContent class="ion-padding">
      <div
        class="flex justify-center mt-20"
      >
        <img
          v-if="tenant && tenant.logo"
          class="logo"
          :src="tenant.logo.downloadUrl"
          alt=""
        >

        <img
          v-else
          class="logo mt-20"
          src="@/assets/Makler_Bild.png"
          alt=""
        >
      </div>
      <p
        v-if="tenant && tenant.welcomeText"
        class="leading-7 mt-10 text-center"
        v-html="tenant.welcomeText"
      />

      <div class="flex justify-center z-10 mt-10">
        <router-link
          :to="{ name: 'ContractList' }"
          class="btn text-white bg-primary px-12"
        >
          Weiter
        </router-link>
      </div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        viewBox="0 0 375 185.584"
        class="absolute inset-x-0 bottom-0 -z-1"
      >
        <path
          id="bgwave"
          data-name="bgwave"
          d="M2008,562c-103.872,0-113.531,70.08-198.662,70.08S1717.145,607.341,1633,607.341V747.584h375Z"
          transform="translate(-1633 -562)"
          class="fill-current text-gray-100 dark:text-gray-900"
        />
      </svg>
    </IonContent>
  </IonPage>
</template>

<script>
import { IonPage, IonContent, IonHeader } from '@ionic/vue'

export default {
  components: {
    IonPage,
    IonContent,
    IonHeader
  },
  data: () => ({
    loading: true,
    tenant: null
  }),
  computed: {
    user(){
      return this.$store.getters['auth/user']
    }
  },
  watch: {
    '$route': {
      handler: 'fetchData',
      immediate: true
    }
  },
  methods: {
    async fetchData() {
      this.loading = true
      const tenantId = this.user.tenantId
      this.tenant = await this.$store.dispatch('tenants/get', tenantId)
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.logo{
  width: 200px;
}
</style>
