<template>
  <IonPage>
    <IonContent
      v-if="faqList"
      class="ion-padding"
    >
      <h2 class="mt-5">
        FAQ
      </h2>
      <div
        v-for="(faq) of faqList"
        :key="faq._id"
        class="rounded-md bg-gray-100 dark:bg-gray-800 p-5 mb-5"
        @click="showAnswer(faq)"
      >
        <p :class="{ 'font-bold': !faq.collapsed }">
          {{ faq.question }}
        </p>
        <Collapse :collapsed="faq.collapsed">
          <div v-html="faq.answer" />
          <p class="text-right">
            <span
              class="text-primary dark:text-secondary py-2"
              @click.stop="hideAnswer(faq)"
            >Verstanden</span>
          </p>
        </Collapse>
      </div>
    </IonContent>
  </IonPage>
</template>

<script>
import { IonPage, IonContent } from '@ionic/vue'
import { faUser, faLock, faEye } from '@fortawesome/pro-solid-svg-icons'
import Collapse from '@/components/Collapse.vue'
export default {
  components: { IonPage, IonContent, Collapse },
  data: () => ({
    faqs: null,
    faUser,
    faLock,
    faEye,
    faqList: null
  }),
  created() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      const faqListReq = await this.$store.dispatch('faq/find')
      this.faqList = faqListReq.data
    },
    showAnswer(faq){
      faq.collapsed = false
    },
    hideAnswer(faq){
      faq.collapsed = true
    }
  }
}

</script>
