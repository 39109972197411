<template>
  <ActionSheetModal

    ref="actionSheetModal"
  >
    <div class="bg-white dark:bg-gray-800 rounded-md ion-padding h-full max-h-custom shadow-lg relative">
      <div class="h-full max-h-custom overflow-y-scroll">
        <!-- Elements -->
        <a
          v-for="link, index in tenant.links"
          :key="`link${index}`"
          :href="link.url"
          target="_blank"
          class="flex items-start text-black mb-5 dark:text-white "
        >
          <font-awesome-icon
            class="w-5 text-primary"
            :icon="faExternalLink"
            size="lg"
          />
          <div class="w-full px-4">
            <h6 class="leading-4 mb-0">
              {{ link.name }}
            </h6>
            <p v-if="link.description">
              {{ link.description }}
            </p>
          </div>
          <div class="w-auto text-black dark:text-white self-center">
            <FontAwesomeIcon
              :icon="faAngleRight"
            />
          </div>
        </a>

        <div
          class="flex items-center mb-5 dark:text-white"
          @click="$router.push({ name: 'FAQ' }); hide()"
        >
          <font-awesome-icon
            class="w-5 text-primary"
            :icon="faQuestion"
            size="lg"
          />
          <div class="w-full px-4">
            <h6 class="leading-4 mb-0">
              FAQ
            </h6>
          </div>
          <div class="w-auto dark:text-white">
            <FontAwesomeIcon
              :icon="faAngleRight"
            />
          </div>
        </div>
        <div
          class="flex items-center  dark:text-white"
          @click="logout"
        >
          <font-awesome-icon
            class="w-5 text-primary"
            :icon="faSignOut"
            size="lg"
          />
          <div class="w-full px-4">
            <h6 class="leading-4 mb-0">
              Abmelden
            </h6>
          </div>
        </div>
        <!-- end -->
      </div>
    </div>
    <button
      class="btn block w-full bg-white text-primary font-bold text-center dark:bg-gray-800 dark:text-white rounded-md ion-padding shadow-lg mt-4 py-4"
      @click="hide()"
    >
      Schließen
    </button>
  </ActionSheetModal>
</template>
<script>
import ActionSheetModal from './ActionSheetModal.vue'
import { faQuestion, faSignOut, faExternalLink } from '@fortawesome/pro-duotone-svg-icons'
import { faAngleRight } from '@fortawesome/pro-solid-svg-icons'
export default {
  components: { ActionSheetModal },
  data(){
    return {
      faQuestion,
      faAngleRight,
      faSignOut,
      faExternalLink,
      tenant: null,
    }
  },
  computed: {
    user(){
      return this.$store.getters['auth/user']
    }
  },
  created(){
    this.fetchdata()
  },
  methods: {
    show(){
      this.$refs.actionSheetModal.show()
    },
    hide(){
      this.$refs.actionSheetModal.hide()
    },
    async fetchdata(){
      const tenantId = this.user.tenantId
      this.tenant = await this.$store.dispatch('tenants/get', tenantId)
    },
    async logout(){
      await this.$store.dispatch('auth/logout')
      this.$router.go({ name: 'Login' })
    }
  }
}
</script>
<style lang="scss" scoped>
.max-h-custom{
  max-height: 40rem;
}
</style>
