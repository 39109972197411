export default function(val, round = false, fixed = 2) {
  let value
  if (typeof value !== 'number') {
    const parsedValue = Number.parseFloat(val)
    if (Number.isNaN(parsedValue)) {
      return value
    }
    value = parsedValue
  } else {
    value = JSON.parse(val)
  }

  if (!round) {
    value = toFixed(value, fixed)
  }

  var formatter = new Intl.NumberFormat('de', {
    minimumFractionDigits: fixed
  })

  return formatter.format(value)
}

function toFixed(num, fixed) {
  var re = new RegExp('^-?\\d+(?:.\\d{0,' + (fixed || -1) + '})?')
  const matches = num.toString().match(re)
  if (matches) {
    return Number.parseFloat(matches[0])
  }
}