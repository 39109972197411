import { createRouter, createWebHistory } from '@ionic/vue-router'
import authenticationGuard from './authenticationGuard.js'
import biometricGuard from './biometricGuard.js'
import ContractList from '../views/contract/ContractList.vue'
import ContractDetail from '../views/contract/ContractDetail.vue'
import ContractCreate from '../views/contract/ContractCreate/ContractCreate.vue'
import CreateProcess from '../views/contract/CreateProcess.vue'
import Login from '../views/Login.vue'
import Welcome from '../views/Welcome.vue'
import ForgetPassword from '../views/forgetPassword/ForgetPassword.vue'
import ChangePassword from '../views/forgetPassword/ChangePassword.vue'
import LayoutTabs from '../layouts/LayoutTabs.vue'
import Contact from '../views/contact/Contact.vue'
import Profile from '../views/profile/Profile.vue'
import ProfilePersonalData from '../views/profile/ProfilePersonalData.vue'
import ProfileLoginData from '../views/profile/ProfileLoginData.vue'
import ProfileAssfinetData from '../views/profile/ProfileAssfinetData'
import CreateDamageReport from '../views/damageReport/CreateDamageReport.vue'
import Faq from '../views/faq/Faq.vue'
import statusBarGuard from './statusBarGuard.js'
import { StatusBarStyle } from '@capacitor/core'
import preloadGuard from './preloadGuard.js'

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/welcome',
    name: 'Welcome',
    component: Welcome
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/forgetPassword',
    name: 'ForgetPassword',
    component: ForgetPassword
  },
  {
    path: '/changePassword',
    name: 'ChangePassword',
    component: ChangePassword
  },
  {
    name: 'LayoutTabs',
    path: '/tabs/',
    component: LayoutTabs,
    children: [
      {
        name: 'ContractList',
        path: '/tabs/contract',
        component: ContractList
      },
      {
        name: 'ContractDetail',
        path: '/tabs/contract/:id',
        component: ContractDetail
      },
      {
        name: 'ContractCreate',
        path: '/tabs/contract/create',
        component: ContractCreate
      },
      {
        name: 'CreateProcess',
        path: '/tabs/contract/:id/create-process',
        component: CreateProcess
      },
      {
        name: 'CreateDamageReport',
        path: '/tabs/contract/:contractId/damage-report',
        component: CreateDamageReport,
      },
      {
        name: 'Contact',
        path: '/tabs/contact',
        component: Contact
      },
      {
        name: 'Profile',
        path: '/tabs/profile',
        component: Profile,
        meta: {
          statusBar: {
            backgroundColor: '#113388',
            statusBarStyle: StatusBarStyle.Dark
          }
        }
      },
      {
        name: 'ProfilePersonalData',
        path: '/tabs/profile/profilePersonalData',
        component: ProfilePersonalData,
      },
      {
        name: 'ProfileLoginData',
        path: '/tabs/profile/profileLoginData',
        component: ProfileLoginData,
      },
      {
        name: 'ProfileAssfinetData',
        path: '/tabs/profile/profileAssfinetData',
        component: ProfileAssfinetData,
      },
      {
        name: 'FAQ',
        path: '/tabs/faq',
        component: Faq
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(authenticationGuard)
router.beforeEach(biometricGuard)
router.beforeEach(statusBarGuard)
router.beforeEach(preloadGuard)

export default router
