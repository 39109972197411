export default async function(value, date){
  const today = new Date()
  const valueDate = new Date(date)
  value = value.split(':')

  valueDate.setHours(value[0], value[1])
  if (valueDate <= today ) {
    return true
  }
  return 'Keine gültige Uhrzeit'
}