<template>
  <IonPage>
    <DamageReportSelectContractModal ref="damageReportSelectContract" />
    <MenuModal ref="menu" />
    <IonTabs
      class="bg-white dark:bg-black"
      :class="{ 'pb-16': !isOpen }"
      @ionTabsWillChange="beforeTabChange"
      @ionTabsDidChange="afterTabChange"
    >
      <IonFab
        vertical="bottom"
        horizontal="center"
        translucent="true"
        :class="{ invisible: isOpen, visible: !isOpen }"
      >
        <IonFabButton
          class="whitespace-normal"
          @click="handleButton"
        >
          <div
            v-if="!tenant || !tenant.callToAction || !tenant.callToAction.app || !tenant.callToAction.app.title"
            class="w-full"
          >
            <font-awesome-icon
              :icon="faLifeRing"
            />
            <p class="leading-3 text-xs">
              Schaden<br>melden
            </p>
          </div>
          <div
            v-else
            class="w-full p-1"
          >
            <p class="leading-3 text-xs mb-0">
              <span
                v-for="(item, index) of tenant.callToAction.app.title.split(' ')"
                :key="index"
              >{{ item }}<br></span>
            </p>
          </div>
        </IonFabButton>
      </IonFab>
      <IonTabBar
        slot="bottom"
        :class="{ invisible: isOpen, visible: !isOpen }"
        class="dark:bg-gray-900 h-16"
      >
        <IonTabButton
          tab="contracts"
          href="/tabs/contract"
        >
          <IonIcon slot="start" />
          <font-awesome-icon
            :icon="faFileContract"
            size="2x"
          />
          <IonLabel>Verträge</IonLabel>
        </IonTabButton>

        <IonTabButton
          tab="profile"
          href="/tabs/profile"
          class="comments"
        >
          <IonIcon slot="start" />
          <font-awesome-icon
            :icon="faUser"
            size="2x"
          />
          <IonLabel>Daten</IonLabel>
        </IonTabButton>
        <svg
          height="50"
          viewBox="0 0 100 50"
          width="100"
          xmlns="http://www.w3.org/2000/svg"
          class="middle-area"
        ><path
          d="M100 0v50H0V0c.543 27.153 22.72 49 50 49S99.457 27.153 99.99 0h.01z"
          fill="red"
          fill-rule="evenodd"
        /></svg>

        <IonTabButton
          tab="contact"
          href="/tabs/contact"
          class="notifs"
        >
          <IonIcon slot="start" />
          <font-awesome-icon
            :icon="faUserTie"
            size="2x"
          />
          <IonLabel>Kontakt</IonLabel>
        </IonTabButton>

        <IonTabButton
          @click="$refs.menu.show()"
        >
          <IonIcon slot="start" />
          <font-awesome-icon
            :icon="faTh"
            size="2x"
          />
          <IonLabel>Mehr</IonLabel>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  </IonPage>
</template>

<script>
import { faFileContract, faUserTie, faUser, faQuestionCircle, faLifeRing } from '@fortawesome/pro-duotone-svg-icons'
import { faPlus, faTh } from '@fortawesome/pro-solid-svg-icons'
import { defineComponent } from 'vue'
import DamageReportSelectContractModal from '../views/damageReport/components/DamageReportSelectContract.vue'
import {
  IonIcon,
  IonLabel,
  IonPage,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonFab,
  IonFabButton,
  useKeyboard
} from '@ionic/vue'
import MenuModal from '../components/MenuModal.vue'

export default defineComponent({
  components: {
    IonIcon,
    IonLabel,
    IonPage,
    IonTabBar,
    IonTabButton,
    IonTabs,
    IonFab,
    IonFabButton,
    DamageReportSelectContractModal,
    MenuModal
  },
  provide() {
    return {
      'openDamageReportSelectContractModal': this.openDamageReportSelectContractModal
    }
  },
  setup() {
    const beforeTabChange = () => {
      // do something before tab change
    }
    const afterTabChange = () => {
      // do something after tab change
    }
    const { isOpen } = useKeyboard()
    return {
      faFileContract,
      faUserTie,
      faUser,
      faQuestionCircle,
      faLifeRing,
      faPlus,
      faTh,
      beforeTabChange,
      afterTabChange,
      isOpen
    }
  },
  data(){
    return {
      tenant: null
    }
  },
  computed: {
    user(){
      return this.$store.getters['auth/user']
    },
  },
  mounted(){
    this.fetchData()
  },
  methods: {
    openDamageReportSelectContractModal() {
      this.$refs.damageReportSelectContract.show()
    },
    async fetchData(){
      this.tenant = await this.$store.dispatch('tenants/get', this.user.tenantId)
    },
    handleButton(){
      if (!this.tenant || !this.tenant.callToAction || !this.tenant.callToAction.app || !this.tenant.callToAction.app.title){
        this.$refs.damageReportSelectContract.show()
      } else {
        window.open(this.tenant.callToAction.app.link, '_blank')
      }
    }
  }
})
</script>

<style lang="scss" scoped>
@media (prefers-color-scheme: dark){
  .ion-selectable, .ion-selectable:focus {
    color: var(--gray-300);
    background-color: var(--black);
    &.tab-selected{
      color: white !important;
    }
  }
}
.router-link-active{
  color: var(--ion-color-primary);
}
ion-tabs{
	ion-fab {
		margin-bottom: env(safe-area-inset-bottom); /* fix notch ios*/
		ion-fab-button {
			--box-shadow: none;
		}
	}
	ion-tab-bar {
		--border: 0;
		--background: transparent;
		position: absolute;
		bottom: 0;
		left:0; right: 0;
		width: 100%;
		&:after{
			content: " ";
			width: 100%;
			bottom: 0;
			background: var(--ion-color-light);
			height: env(safe-area-inset-bottom);
			position: absolute;
		}
		ion-tab-button {
			--background: var(--ion-color-light);
		}
		ion-tab-button.comments {
			margin-right: 0px;
			border-top-right-radius: 18px;
		}
		ion-tab-button.notifs {
			margin-left: 0px;
			border-top-left-radius: 18px;
		}
		.middle-area {
			width: 72px;
			margin-top: 27px;
			path{
				fill:  var(--ion-color-light);
        @apply fill-current text-gray-100 dark:text-gray-900
			}
		}
	}
}
</style>
