<template>
  <IonPage>
    <IonHeader class="ion-no-border">
      <IonToolbar mode="ios">
        <IonButtons slot="start">
          <IonBackButton
            mode="md"
            class="text-primary dark:text-white"
          />
        </IonButtons>
        <IonTitle>
          Daten bearbeiten
        </IonTitle>
      </IonToolbar>
    </IonHeader>
    <IonContent class="ion-padding">
      <h4 class="mt-2">
        Logindaten
      </h4>
      <IonToast
        :is-open="open"
        :duration="2000"
        message="Speichern erfolgreich"
        mode="ios"
      />
      <Form
        @submit="save"
      >
        <div class="mt-4">
          <div class="mt-1">
            <label
              for="email"
              class="block text-sm font-medium text-gray-700 dark:text-gray-500"
            >Email</label>
            <input
              id="email"
              v-model="user.email"
              type="text"
              name="email"
              disabled
            >
            <!-- <BasicInput
              id="email"
              v-model="user.email"
              type="email"
              name="email"
              label-class="block text-sm font-medium text-gray-700 dark:text-gray-500"
              label="Email"
              placeholder="you@example.com"
              rules="email"
            /> -->
          </div>
        </div>
        <div class="mt-4">
          <div class="mt-1">
            <BasicInput
              id="password"
              v-model="user.password"
              type="password"
              name="password"
              label="Passwort"
              label-class="block text-sm font-medium text-gray-700 dark:text-gray-500"
              rules="password-strength"
              placeholder="*********"
            />
          </div>
        </div>

        <div class="mt-4">
          <div class="mt-1">
            <BasicInput
              id="confirmPassword"
              v-model="user.confirmpassword"
              type="password"
              name="confirmPassword"
              label-class="block text-sm font-medium text-gray-700 dark:text-gray-500"
              label="Passwort bestätigen"
              rules="confirmed:@password"
              placeholder="*********"
            />
          </div>
        </div>
        <div class="text-right">
          <input
            type="submit"
            value="Speichern"
            class="btn bg-primary text-white mt-4"
          >
        </div>
      </Form>
    </IonContent>
  </IonPage>
</template>
<script>
import { faCheck } from '@fortawesome/pro-solid-svg-icons'
import { Form } from 'vee-validate'
import { IonPage, IonToast, IonContent, IonHeader, IonToolbar, IonButtons, IonBackButton, IonTitle } from '@ionic/vue'
import BasicInput from '../../components/BasicInput.vue'
export default {
  components: {
    IonPage,
    IonContent,
    IonToast,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonTitle,
    Form,
    BasicInput
  },
  data: () => ({
    faCheck,
    open: false,
    password: null,
    confirmpassword: null
  }),
  computed: {
    user() {
      return this.$store.getters['auth/user']
    }
  },
  methods: {
    async save(){
      await this.user.save()
      this.setOpen(true)
    },
    setOpen(value){
      this.open = value
    },
  }
}
</script>
