<template>
  <div>
    <h4 class="my-2">
      Schadendaten
    </h4>
    <div class="mt-4">
      <BasicInput
        id="damageDate"
        v-model="localValue.date"
        type="date"
        name="damageDate"
        label="Schadentag*"
        rules="required|disabled-future-dates"
      />
    </div>
    <div class="mt-4">
      <BasicInput
        id="damageTime"
        v-model="localValue.time"
        type="time"
        name="damageTime"
        label="Schadenuhrzeit*"
        rules="required|disabled-future-time:@damageDate"
      />
    </div>

    <basic-input
      id="damageLocation"
      v-model="localValue.location"
      class="mt-4"
      label="Schadenort"
    />
    <basic-input
      id="amount"
      v-model="localValue.amount"
      class="mt-4"
      type="number"
      label="Schadenhöhe (ggf. Schätzung)*"
    />

    <p class="mt-4 text-xs">
      * Pflichtfelder
    </p>
    <h4 class="my-2">
      Aktenzeichen
    </h4>

    <div class="mt-4">
      <BasicInput
        id="tenantNumber"
        v-model="localValue.tenantNumber"
        name="tenantNumber"
        type="text"
        label="Referenznummer Makler"
      />
    </div>
    <div class="mt-4">
      <BasicInput
        id="ownVN"
        v-model="localValue.ownVN"
        name="ownVN"
        type="text"
        label="Eigene Referenznummer"
      />
    </div>

    <h4 class="my-2">
      Allgemein
    </h4>

    <div class="mt-4">
      <BasicInput
        id="nameOrCompany"
        v-model="localValue.nameOrCompany"
        name="nameOrCompany"
        type="text"
        label="Name/Firma"
      />
    </div>

    <div class="mt-4">
      <BasicInput
        id="contactPerson"
        v-model="localValue.contactPerson"
        name="contactPerson"
        type="text"
        label="Ansprechpartner"
      />
    </div>

    <div class="mt-4">
      <BasicInput
        id="phone"
        v-model="localValue.phone"
        name="phone"
        type="text"
        label="Telefon"
      />
    </div>

    <h4 class="my-2">
      Anschrift
    </h4>
    <div class="mt-4">
      <BasicInput
        id="customerStreet"
        v-model="localValue.customerStreet"
        name="customerStreet"
        type="text"
        label="Strasse"
      />
    </div>

    <div class="mt-4">
      <BasicInput
        id="customerZipCode"
        v-model="localValue.customerZipCode"
        name="customerZipCode"
        type="text"
        label="Postleitzahl"
      />
    </div>

    <div class="mt-4">
      <BasicInput
        id="customerCity"
        v-model="localValue.customerCity"
        name="customerCity"
        type="text"
        label="Ort"
      />
    </div>

    <div class="mt-4">
      <BasicCheckbox
        id="maklerFuehrtVersSteuerAb"
        v-model="localValue.maklerFuehrtVersSteuerAb"
        name="maklerFuehrtVersSteuerAb"
        label="Sind Sie vorsteuerabzugsberechtigt?"
      />
    </div>
    <h4 class="my-2">
      Schaden­vorfall
    </h4>
    <div class="mt-4">
      <BasicInput
        id="title"
        v-model="localValue.title"
        type="text"
        name="title"
        label="Betreff"
      />
    </div>

    <div class="mt-4">
      <BasicInput
        id="description"
        v-model="localValue.description"
        as="textarea"
        name="description"
        rows="3"
        label="Was ist passiert?"
        small="Bitte beschreiben Sie den Schaden­vorfall."
      />
    </div>

    <div class="mt-4">
      <BasicCheckbox
        id="attachmentsComplete"
        v-model="localValue.attachmentsComplete"
        name="attachmentsComplete"
        label="Belege liegen vollständig bei"
      />
    </div>

    <div class="mt-4">
      <BasicCheckbox
        id="attachmentsPartially"
        v-model="localValue.attachmentsPartially"
        name="attachmentsPartially"
        label="Belege liegen zum Teil bei"
      />
    </div>

    <div class="mt-4">
      <BasicCheckbox
        id="attachmentsFollow"
        v-model="localValue.attachmentsFollow"
        name="attachmentsFollow"
        label="Belege folgen"
      />
    </div>

    <div class="mt-4">
      <BasicCheckbox
        id="imagesAttached"
        v-model="localValue.imagesAttached"
        name="imagesAttached"
        label="Bilder liegen bei"
      />
    </div>

    <div class="my-4">
      <file-upload @upload="upload" />
    </div>
    <file-preview
      v-for="(file, index) in Array.from(localValue.files)"
      :key="file.name"
      :file="file"
      @removeFile="localValue.files.splice(index)"
    />
  </div>
</template>
<script>
import { reactive, toRef, watch, ref } from 'vue'

import BasicInput from '../../../components/BasicInput.vue'
import FileUpload from '../../../components/FileUpload.vue'
import FilePreview from '../../../components/FilePreview.vue'
import BasicCheckbox from '../../../components/BasicCheckbox.vue'
import feathersClient from '../../../feathers-client'
import axios from 'axios'

export default {
  components: { FileUpload, BasicInput, FilePreview, BasicCheckbox },
  props: {
    modelValue: {
      type: Object,
      default: () => {}
    }
  },
  emits: [ 'update:modelValue' ],
  setup(props, { emit }) {
    const modelValue = toRef(props, 'modelValue')
    const localValue = reactive({ ...modelValue.value })

    let testBool = ref(true)

    watch(() => localValue, (val) => emit('update:modelValue', val), { deep: true })

    async function upload(event) {
      Array.from(event.target.files).map(async toUploadFile => {
        try {
          const file = await feathersClient.service('file').create({
            name: toUploadFile.name,
            mimeType: toUploadFile.type
          })
          await axios.put(file.uploadUrl, toUploadFile)

          localValue.files.push(file)
        } catch (error) {
          console.error(error)
        }
      })
    }

    // async function upload(event) {
    //   localValue.files.push(...Array.from(event.target.files).map(file => {
    //     return {
    //       name: file.name,
    //       type: file.type,
    //       size: file.size,
    //       file: file
    //     }
    //   }))
    // }

    return {
      localValue,
      upload,
      testBool,
    }
  }
}
</script>
