<template>
  <IonPage>
    <IonHeader class="ion-no-border">
      <IonToolbar mode="ios">
        <IonButtons slot="start">
          <IonBackButton
            mode="md"
            class="text-primary dark:text-white"
          />
        </IonButtons>
        <IonTitle v-if="contract">
          {{ contract.contractKind?.name }}
        </IonTitle>
      </IonToolbar>
    </IonHeader>
    <IonContent
      v-if="contract"
      class="ion-padding pb-16"
    >
      <div class="pb-4">
        <div class="flex items-center mb-5">
          <div class="w-12 h-12">
            <img
              v-if="contract.contractCompany?.logo"
              :src="contract.contractCompany.logo.downloadUrl"
              class="rounded-md h-full object-contain shadow-md"
              alt=""
            >
            <img
              v-if="!contract.contractCompany?.logo"
              class="rounded-md h-full object-contain shadow-md"
              src="@/assets/default-logo.jpg"
            >
          </div>
          <h5 class="pl-4">
            {{ contract?.contractCompany?.name }}
          </h5>
        </div>
        <DescriptionValue
          description="Bemerkung / Was ist Versichert"
          :value="contract.comment"
        />
        <DescriptionValue
          description="Status"
          :value="getHumanContractStatus(contract.status)"
        />
        <DescriptionValue
          description="Vertragsnummer"
          :value="contract.number"
        />
        <DescriptionValue
          v-if="contract.data && contract.data.RisikoAllgemein"
          description="Allgemeines Risiko"
          :value="contract.data.RisikoAllgemein"
        />
        <DescriptionValue
          description="Vertragsbeginn"
          :value="dayjs(contract.startDate).format('DD.MM.YYYY')"
        />
        <DescriptionValue
          description="Vertragsende"
          :value="dayjs(contract.endDate).format('DD.MM.YYYY')"
        />
        <DescriptionValue
          description="Zahlweise"
          :value="getHumanPaymentType(contract.paymentType)"
        />
        <DescriptionValue
          description="Jahresprämie"
          :value="`${formatNumber(contract.jahrespraemieBrutto)}${formatCurrency(contract.currency)}`"
        />
        <DescriptionValue
          description="Prämie gemäß Zahlweise"
          :value="`${formatNumber(contract.praemieZahlweiseBrutto)}${formatCurrency(contract.currency)} ${getHumanPaymentType(contract.paymentType)}`"
        />
        <div v-if="contract.origin==='ASSFINET'">
          <button
            v-if="userCanCreateDamageReport"
            class="btn bg-primary text-white w-full mt-4"
            @click="$router.push({ name: 'CreateDamageReport', params: { contractId: $route.params.id } })"
          >
            Schadenmeldung
          </button>
          <h4
            v-if="hasSubContracts"
            class="mt-8"
          >
            Unterverträge
          </h4>
          <div
            v-if="hasSubContracts"
            class="list-wrapper"
          >
            <ListItem
              v-for="subContract of subContracts"
              :key="subContract._id"
              :contract="subContract"
              @click="() => {
                $router.push({ name: 'ContractDetail', params: { id: subContract._id } })
              }"
            />
          </div>
          <button
            v-if="userCanCreateProcesses"
            class="btn bg-primary text-white w-full mb-4 mt-6"
            @click="$router.push({ name: 'CreateProcess', params: { id: $route.params.id } })"
          >
            Neue Nachricht
          </button>
          <div>
            <h4>Vertragsanhänge</h4>
            <p v-if="contract.attachments && contract.attachments.length === 0">
              Keine Vertragsanhänge vorhanden.
            </p>
            <div
              v-for="(file, fileIndex) in contract.attachments"
              :key="`file-${fileIndex}`"
              class="btn btn-link text-primary dark:bg-primary dark:text-white px-4 w-full flex mt-2"
              @click="openLinkHandler(file)"
            >
              <FontAwesomeIcon
                :icon="faFilePdf"
              />
              <span class="overflow-hidden overflow-ellipsis whitespace-nowrap ml-2">
                {{ file.name }}
              </span>
            </div>
          </div>
          <div v-if="processes.length > 0">
            <h4>
              Nachrichten
            </h4>
            <p v-if="processes && processes.length === 0">
              Keine Nachrichten vorhanden.
            </p>
            <ProcessItem
              v-for="(process, index) in processes"
              :key="`process-${index}`"
              :process="process"
              class="rounded-md bg-gray-100 dark:bg-gray-800 p-5 mb-5"
            />
          </div>
        </div>
        <p
          v-if="contract.s3Files"
          class="text-sm text-gray-400 mb-0 mt-4"
        >
          Hochgeladene Dateien
        </p>
        <a
          v-for="(file, fileIndex) in contract.s3files"
          :key="`file-${fileIndex}`"
          class="text-primary dark:bg-primary dark:text-white text-left w-full mt-2 block"
          :href="file.downloadUrl"
        >
          <span class="overflow-hidden overflow-ellipsis whitespace-nowrap">
            {{ file.name }}
          </span>
        </a>
      </div>
    </IonContent>
  </IonPage>
</template>

<script>
import { IonPage, IonHeader, IonToolbar, IonTitle, IonButtons, IonBackButton, IonContent } from '@ionic/vue'
import ListItem from './components/ListItem.vue'
import DescriptionValue from './components/DescriptionValue.vue'
import dayjs from 'dayjs'
import formatCurrency from '@/utilities/filters/formatCurrency'
import formatNumber from '@/utilities/filters/formatNumber'
import ProcessItem from './components/ProcessItem.vue'
import contractSort from '@/utilities/sorts/contractSort'
import { faFilePdf } from '@fortawesome/pro-duotone-svg-icons'
import openLink from '@/utilities/openLink'

export default {
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonButtons,
    IonBackButton,
    DescriptionValue,
    ProcessItem,
    ListItem
  },
  data: () => ({
    dayjs,
    faFilePdf,
    host: process.env.VUE_APP_API_HOST,
  }),
  async ionViewDidEnter() {
    await this.$store.dispatch('processes/find',{
      query: {
        contractId: this.$route.params.id,
        $client: {
          paginate: false
        }
      }
    })
  },
  computed: {
    contract() {
      return this.$store.getters['contracts/get'](this.$route.params.id)
    },
    processes() {
      let a = this.$store.getters['processes/find']({
        query: {
          contractId: this.$route.params.id,
          $sort: {
            createdAt: -1
          }
        }
      })
      return a.data
    },
    subContracts() {
      let list = this.$store.getters['contracts/find']({ query: { parentContractId: this.contract._id } }).data
      return list.sort(contractSort)
    },
    hasSubContracts() {
      return this.contract.isParentContract || false
    },
    jwt() {
      return this.$store.state.auth.accessToken
    },
    user(){
      return this.$store.getters['auth/user']
    },
    userPermissions(){
      if (!this.user) return []
      return this.user.permissions
    },
    userCanCreateProcesses(){
      if (this.userPermissions.length === 0) return false
      return this.userPermissions.every(permissionObject => permissionObject.CanCreateProcesses === true)
    },
    userCanCreateDamageReport(){
      if (this.userPermissions.length === 0) return false
      return this.userPermissions.every(permissionObject => permissionObject.Schadenmeldung === true)
    }
  },
  methods: {
    formatCurrency,
    formatNumber,
    openLink,
    openLinkHandler(file) {
      if (file.originalContentType === 'application/pdf' || file.originalContentType === 'image/png' || file.originalContentType === 'image/jpg' || file.originalContentType === 'image/jpeg') {
        this.openLink(`${this.host}api/contracts/${this.contract._id}/attachments/${file.data.Id}?jwt=${this.jwt}`)
      } else {
        this.openLink(`${this.host}api/contracts/${this.contract._id}/attachments/${file.data.Id}?jwt=${this.jwt}`, 'external')
      }
    },
    toggleProcess(index){
      this.processes[index].collapsed = !this.processes[index].collapsed
    },
    showProcess(index){
      this.processes[index].collapsed = false
    },
    hideProcess(index){
      this.processes[index].collapsed = true
    },
    getHumanPaymentType(apiPaymentType) {
      return this.$store.getters['enums/humanPaymentType'](apiPaymentType)
    },
    getHumanContractStatus(apiContractStatus) {
      return this.$store.getters['enums/humanContractStatus'](apiContractStatus)
    }
  }
}

</script>
