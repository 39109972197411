<template>
  <IonPage>
    <IonHeader class="ion-no-border">
      <IonToolbar mode="ios">
        <IonButtons slot="start">
          <IonBackButton
            mode="md"
            class="text-primary dark:text-white"
            css-class="relative mb-12"
          />
        </IonButtons>
        <IonTitle>
          Daten bearbeiten
        </IonTitle>
      </IonToolbar>
    </IonHeader>
    <IonContent class="ion-padding">
      <h4 class="mt-2">
        Persönliche Informationen
      </h4>
      <Form
        v-if="user"
        @submit="save"
      >
        <div class="mt-4">
          <BasicInput
            id="firstname"
            v-model="user.firstname"
            type="text"
            name="firstName"
            label="Vorname"
            label-class="block text-sm font-medium text-gray-700 dark:text-gray-500"
            placeholder="Max"
            rules="required"
          />
        </div>
        <div class="mt-4">
          <BasicInput
            id="lastname"
            v-model="user.lastname"
            type="text"
            name="lastName"
            label="Nachname"
            label-class="block text-sm font-medium text-gray-700 dark:text-gray-500"
            placeholder="Mustermann"
            rules="required"
          />
        </div>
        <div class="mt-4">
          <BasicInput
            id="birthday"
            v-model="birthday"
            type="date"
            rules="required|disabled-birthdays"
            name="birthday"
            label="Geburtsdatum"
            label-class="block text-sm font-medium text-gray-700 dark:text-gray-500"
          />
          <div class="mt-4">
            <BasicInput
              id="street"
              v-model="user.address.street"
              type="text"
              rules="required"
              label="Straße"
              label-class="block text-sm font-medium text-gray-700 dark:text-gray-500"
            />
          </div>
          <div class="mt-4">
            <BasicInput
              id="streetNumber"
              v-model="user.address.streetNumber"
              type="text"
              rules="required"
              label="Hausnummer"
              label-class="block text-sm font-medium text-gray-700 dark:text-gray-500"
            />
          </div>
          <div class="mt-4">
            <BasicInput
              id="zip"
              v-model="user.address.zip"
              type="text"
              rules="required"
              label="Postleitzahl"
              label-class="block text-sm font-medium text-gray-700 dark:text-gray-500"
            />
          </div>
          <div class="mt-4">
            <BasicInput
              id="city"
              v-model="user.address.city"
              type="text"
              rules="required"
              label="Ort"
              label-class="block text-sm font-medium text-gray-700 dark:text-gray-500"
            />
          </div>
        </div>
        <div class="text-right">
          <input
            type="submit"
            value="Speichern"
            class="btn bg-primary text-white mt-4"
          >
        </div>
      </Form>
    </IonContent>
  </IonPage>
</template>
<script>
import { faCheck } from '@fortawesome/pro-solid-svg-icons'
import { IonPage, IonContent, IonHeader, IonToolbar, IonButtons, IonBackButton, IonTitle, toastController } from '@ionic/vue'
import dayjs from 'dayjs'
import { Form } from 'vee-validate'
import BasicInput from '../../components/BasicInput.vue'
export default {
  components: {
    IonPage,
    IonContent,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonTitle,
    BasicInput,
    Form
  },
  data: () =>({
    faCheck,
    dayjs,
    user: null
  }),
  computed: {
    birthday: {
      get(){
        return dayjs(this.user.birthday).format('YYYY-MM-DD')
      },
      set(value){
        this.user.birthday = value
      }
    }
  },
  created(){
    this.fetchData()
  },
  methods: {
    async fetchData(){
      this.user = await this.$store.getters['auth/user']
      if (this.user.address == null) {
        this.user.address = {
          street: '',
          streetNumber: '',
          city: '',
          zip: ''
        }
      }
    },
    async save() {
      await this.user.save()
      this.openToast()
      this.$router.push({ name: 'Profile' })
    },
    async openToast(){
      const toast = await toastController
        .create({
          message: 'Speichern erfolgreich',
          duration: 4000,
          mode: 'ios',
          cssClass: 'transform -translate-y-16'
        })
      return toast.present()
    },
  }
}
</script>
