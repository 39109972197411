import feathers from '@feathersjs/feathers'
import socketio from '@feathersjs/socketio-client'
import auth from '@feathersjs/authentication-client'
import io from 'socket.io-client'
import { iff, discard } from 'feathers-hooks-common'
import feathersVuex from '@feathersjs/vuex'

const socket = io(process.env.VUE_APP_API_HOST, { transports: [ 'websocket' ], path: process.env.VUE_APP_API_PATH })

const feathersClient = feathers()
  .configure(socketio(socket, { timeout: 120000 }))
  .configure(auth({ storage: window.localStorage }))
  .hooks({
    before: {
      all: [
        iff(
          context => [ 'create', 'update', 'patch' ].includes(context.method),
          discard('__id', '__isTemp')
        )
      ]
    }
  })

export default feathersClient

// Setting up @feathersjs/vuex
const { makeServicePlugin, makeAuthPlugin, BaseModel, models, FeathersVuex } = feathersVuex(
  feathersClient,
  {
    serverAlias: 'api', // optional for working with multiple APIs (this is the default value)
    idField: '_id', // Must match the id field in your database table/collection
    whitelist: [ '$regex', '$options' ],
    merge: function(original, newData) {
      Object.assign(original, newData)
    }
  }
)


export { makeAuthPlugin, makeServicePlugin, BaseModel, models, FeathersVuex }
