import * as Sentry from '@sentry/vue'
import store from '@/store/'

const publicPageNames = [
  'Login',
  'ForgetPassword',
  'ChangePassword'
]

async function getTenant() {
  const user = store.getters['auth/user']
  const tenantId = user.tenantId
  const tenant = store.getters['tenants/get'](tenantId)
  if (!tenant) await store.dispatch('tenants/get', tenantId)
}

export default async function authenticationGuard(to, from, next) {
  const isLoggedIn = store.getters['auth/isAuthenticated']

  if (isLoggedIn) {
    await getTenant()
    return next()
  }

  try {
    await store.dispatch('auth/authenticate')
    Sentry.setUser({ id: store.state.auth.user._id })
    await store.dispatch('sync-start/get', {
      contracts: true,
      processes: true
    })
    await getTenant()
    next()
  } catch (exception) {
    let toPublicPage = publicPageNames.includes(to.name)
    if (toPublicPage) return next()
    console.error(exception)
    return next({ name: 'Login', query: { redirect: to.path, ...to.query } })
  }
}
