import { Plugins } from '@capacitor/core'

const { Browser } = Plugins

export default async function(url, openInExternalBrowser) {
  if (!url) return
  if (openInExternalBrowser) {
    return window.open(url, '_blank')
  }
  await Browser.open({ url, presentationStyle: 'popover' })
}
