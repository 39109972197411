<template>
  <div v-if="value">
    <p
      v-if="description"
      class="text-sm text-gray-400 mb-0 mt-4"
    >
      {{ description }}
    </p>
    <p
      v-if="value"
      class="font-bold"
    >
      {{ value }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    description: {
      type: String,
      default: undefined
    },
    value: {
      type: String,
      default: undefined
    }
  }
}
</script>