<template>
  <IonApp>
    <IonRouterOutlet />
  </IonApp>
</template>

<script>
import { IonApp, IonRouterOutlet, alertController } from '@ionic/vue'
import { defineComponent } from 'vue'
import { Plugins, StatusBarStyle } from '@capacitor/core'
import * as Sentry from '@sentry/vue'
import './theme/tailwind.scss'
import statusBarErrorResolver from '@/utilities/statusBarErrorResolver'

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet
  },
  data: () => ({
    color: '#ff0000'
  }),
  computed: {
    user(){
      return this.$store.getters['auth/user']
    },
    tenant(){
      if (!this.user || !this.user.tenantId) return
      const tenantId = this.user.tenantId
      const tenant = this.$store.getters['tenants/get'](tenantId)
      return tenant
    }
  },
  watch: {
    '$route': {
      handler: 'fetchData',
      immediate: true
    },
    'tenant.color': {
      handler: 'updateColor',
      immediate: true
    },
  },
  created(){
    const { StatusBar } = Plugins
    StatusBar.setBackgroundColor({ color: '#ffffff' }).catch(statusBarErrorResolver)
    StatusBar.setStyle({ style: StatusBarStyle.Light }).catch(statusBarErrorResolver)
  },
  methods: {
    updateColor(color){
      if (!color) return
      document.documentElement.style.setProperty('--primary', color)
    },
    updateColorDark(color){
      if (!color) return
      document.documentElement.style.setProperty('--primary-dark', color)
    },
    async fetchData() {
      try {
        if (this.user) {
          const tenantId = this.user.tenantId
          await this.$store.dispatch('tenants/get', tenantId)
        }
      } catch (error) {
        Sentry.captureException(error)
        const alert = await alertController.create({
          header: 'Fehler',
          message: error.message,
          buttons: [ 'Okay' ]
        })
        alert.present()
      }
    }
  }
})
</script>
