<template>
  <div>
    <section>
      <damage-report-form-general
        v-model="localValue.general"
      />
    </section>
    <!-- ==========Standart START ============= -->
    <section v-if="contractBranch === 'Default'">
      <damage-report-form-mask-default v-model="localValue.default" />
    </section>
    <!-- ==========Standart ENDE ============= -->

    <!-- ==========SACH START ============= -->
    <section v-if="contractBranch === 'Sach'">
      <damage-report-form-mask-sach v-model="localValue.sach" />
    </section>

    <!-- ==========SACH ENDE ============= -->

    <!-- ==========Haftpflicht START ============= -->
    <section v-if="contractBranch === 'Haftpflicht'">
      <damage-report-form-mask-haftpflicht v-model="localValue.haftpflicht" />
    </section>
    <!-- ==========Haftpflicht ENDE ============= -->

    <!-- ==========KFZ START ============= -->
    <section v-if="contractBranch === 'Car'">
      <damage-report-form-mask-kfz v-model="localValue.car" />
    </section>
    <!-- ==========KFZ ENDE ============= -->

    <!-- ==========RS START ============= -->
    <section v-if="contractBranch === 'Default'">
      <damage-report-form-mask-rs v-model="localValue.rs" />
    </section>
    <!-- ==========RS ENDE ============= -->

    <!-- ==========BAU START ============= -->

    <section v-if="contractBranch === 'Bau'">
      <damage-report-form-mask-bau v-model="localValue.bau" />
    </section>

    <!-- ==========BAU ENDE ============= -->

    <section>
      <damage-report-form-bank
        v-model="localValue.bank"
      />
    </section>
    <section class="my-4">
      <p>Verändern Sie die Schadenstelle - mit Ausnahme von erforderlichen Schadenminderungsmaßnahmen - nicht und beginnen Sie erst mit den Aufräumungsarbeiten, wenn der Versicherer und/oder die Polizei den Schadenort freigegeben haben. Beschädigte Teile sind zur Beweissicherung bis zur endgültigen Schadenregulierung aufzubewahren. Erstellen Sie Fotos vom Schadenbereich. Beachten Sie bitte auch das Merkblatt „Verhalten im Schadenfall“.</p>
      <p>Alle Fragen sind wahrheitsgemäß und nach bestem Wissen zu beantworten. Nichtbeantwortung einer Frage gilt als Verneinung. Bewusst wahrheitswidrige oder unvollständige Angeben führen zum Verlust des Versicherungsschutzes, auch wenn dem Versicherer hierdurch kein Nachteil entsteht.</p>

      <BasicCheckbox
        id="confirmation"
        v-model="localValue.confirmation"
        name="OK"
        label="OK"
        rules="required"
      />
    </section>
  </div>
</template>
<script>
import DamageReportFormBank from './DamageReportFormBank.vue'
import DamageReportFormGeneral from './DamageReportFormGeneral.vue'
import DamageReportFormMaskBau from './DamageReportFormMaskBau.vue'
import DamageReportFormMaskDefault from './DamageReportFormMaskDefault.vue'
import DamageReportFormMaskHaftpflicht from './DamageReportFormMaskHaftpflicht.vue'
import DamageReportFormMaskKfz from './DamageReportFormMaskKfz.vue'
import DamageReportFormMaskRs from './DamageReportFormMaskRs.vue'
import DamageReportFormMaskSach from './DamageReportFormMaskSach.vue'

import { reactive, toRef, watch } from 'vue'
import BasicCheckbox from '../../../components/BasicCheckbox.vue'

export default {
  components: { DamageReportFormBank, DamageReportFormMaskBau, DamageReportFormMaskRs, DamageReportFormMaskHaftpflicht, DamageReportFormMaskDefault, DamageReportFormGeneral, DamageReportFormMaskKfz, DamageReportFormMaskSach, BasicCheckbox  },
  props: {
    modelValue: {
      type: Object,
      default: () => {}
    },
    contractBranch: {
      type: String,
      default: 'Default'
    }
  },
  emits: [ 'update:modelValue' ],
  setup(props, { emit }) {
    const modelValue = toRef(props, 'modelValue')
    const localValue = reactive({ ...modelValue.value })

    watch(() => localValue, (val) => emit('update:modelValue', val), { deep: true })

    return {
      localValue
    }
  }
}
</script>
