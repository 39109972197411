<template>
  <IonPage>
    <teleport to="body">
      <ListFilterModal
        ref="listFilterModal"
        name="listFilterModal"
        @choice="choiceHandler"
      />
      <ListFilterModal
        ref="listFilterModalArt"
        name="listFilterModalArt"
        @choice="choiceHandler"
      />
    </teleport>
    <IonHeader class="ion-no-border">
      <IonToolbar mode="ios">
        <IonButtons slot="start">
          <IonBackButton
            mode="md"
            class="text-primary dark:text-white"
            css-class="relative mb-12"
          />
        </IonButtons>
        <IonTitle>
          Vertrag hinzufügen
        </IonTitle>
      </IonToolbar>
    </IonHeader>
    <IonContent>
      <div class="ion-padding">
        <h4>Vertragsdaten</h4>
        <Form
          v-if="!pending"
          data-test="contract-form"
          @submit="addContract"
        >
          <div class="grid gap-4">
            <BasicInput
              id="contractCompany"
              v-model="contractCompany"
              disabled
              type="text"
              label="Vertragspartner"
              data-test="vertragspartner"
              rules="required"
              @click="$refs.listFilterModal.show({ sender: 'contractCompany', itemsToFilter: contractCompaniesList })"
            />
            <BasicInput
              id="contractKind"
              v-model="contractKind"
              disabled
              type="text"
              label="Vertragsart"
              data-test="vertragsart"
              rules="required"
              @click="$refs.listFilterModalArt.show({ sender: 'contractKind', itemsToFilter: contractKinds })"
            />
            <BasicInput
              id="contractObject"
              v-model="contract.comment"
              type="text"
              data-test="contractObject"
              label="Bemerkung/Was ist versichert?"
              rules="required"
            />
            <BasicInput
              id="contractStatus"
              v-model="contract.status"
              data-test="contracStatus"
              as="select"
              label="Status"
            >
              <option value="FREMD">
                {{ getHumanContractStatus("FREMD") }}
              </option>
              <option value="STORNO">
                {{ getHumanContractStatus("STORNO") }}
              </option>
              <option value="CANCELLED">
                {{ getHumanContractStatus("CANCELLED") }}
              </option>
            </BasicInput>
            <BasicInput
              id="contractNumber"
              v-model="contract.number"
              data-test="contractNumber"
              type="text"
              label="Vertragsnummer"
              rules="required"
            />
            <BasicInput
              id="contractPayment"
              v-model="contract.paymentType"
              data-test="contractPayment"
              as="select"
              label="Zahlweise"
              rules="required"
            >
              <option value="MONTHLY">
                {{ getHumanPaymentType("MONTHLY") }}
              </option>
              <option value="QUARTERLY">
                {{ getHumanPaymentType("QUARTERLY") }}
              </option>
              <option value="SEMI_ANUALLY">
                {{ getHumanPaymentType("SEMI_ANUALLY") }}
              </option>
              <option value="ANUALLY">
                {{ getHumanPaymentType("ANUALLY") }}
              </option>
            </BasicInput>
            <BasicInput
              id="contractPrice"
              v-model="contract.praemieZahlweiseBrutto"
              data-test="contractPrice"
              label="Prämie gem. Zahlweise in €"
              type="text"
              rules="required"
            />
            <BasicInput
              id="contractPriceAnually"
              v-model="computedPraemie"
              data-test="contractPriceAnually"
              label="Prämie pro Jahr in €"
              type="text"
              disabled
            />
            <BasicInput
              id="contractBegin"
              v-model="startDate"
              data-test="contractBegin"
              label="Vertragsbeginn"
              type="date"
              rules="required"
            />
            <BasicInput
              id="contractEnd"
              v-model="endDate"
              data-test="contractEnd"
              label="Vertragsende"
              type="date"
              rules="required|later-date:@contractBegin"
            />
            <BasicCheckbox
              v-if="tenant"
              id="visibleForTenant"
              v-model="contract.visibleForTenant"
              data-test="visibleForTenant"
              :label="tenant.customerContractsOptions.alwaysSendToAgentText || 'Ihrem Makler zeigen?'"
              :disabled="true"
            />
            <div class="mt-4">
              <file-upload
                @upload="upload"
              />
              <file-preview
                v-for="file in contract.s3files"
                :key="file"
                :file="file"
                @removeFile="removeFile(file)"
              />
            </div>
            <div class="text-right mb-8">
              <button
                type="submit"
                data-test="contract-submit"
                class="btn bg-primary text-white mt-4"
              >
                Hinzufügen
              </button>
            </div>
          </div>
        </Form>
      </div>
    </IonContent>
  </IonPage>
</template>

<script>
import { IonPage, IonContent, IonHeader, IonTitle, IonToolbar, IonButtons, IonBackButton, toastController, alertController, loadingController } from '@ionic/vue'
import BasicInput from '../../../components/BasicInput.vue'
import BasicCheckbox from '../../../components/BasicCheckbox.vue'
import FileUpload from '../../../components/FileUpload.vue'
import FilePreview from '../../../components/FilePreview.vue'
import ListFilterModal from './components/ListFilterModal.vue'
import { models } from '@/feathers-client'
import { Form } from 'vee-validate'
import dayjs from 'dayjs'
import feathers from '@/feathers-client'
import axios from 'axios'

export default {
  components: {
    IonContent,
    IonPage,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonBackButton,
    BasicInput,
    BasicCheckbox,
    FileUpload,
    FilePreview,
    Form,
    ListFilterModal
  },
  data(){
    return {
      dayjs,
      contract: new models.api.ContractOwnCreate({
        status: 'FREMD',
        visibleForTenant: true,
        paymentType: 'MONTHLY',
        s3files: [],
        s3fileIds: [],
      }),
      itemsToFilter: [],
      contractKind: '',
      contractCompany: '',
      visibleForTenant: true,
      tenant: null,
      pending: false,
      fileData: []
    }
  },
  computed: {
    user() {
      return this.$store.getters['auth/user']
    },
    contractCompaniesList() {
      return this.$store.getters['assfinet-contract-companies/list']
        .map(company => {
          return {
            ...company,
            name: company.Name1
          }
        })
    },
    contractKinds() {
      return this.$store.getters['assfinet-contract-kinds/list']
    },
    startDate: {
      get(){
        return null
      },
      set(value){
        this.contract.startDate = dayjs(value).format('YYYY-MM-DD')
      }
    },
    endDate: {
      get(){
        return null
      },
      set(value){
        this.contract.endDate = dayjs(value).format('YYYY-MM-DD')
      }
    },
    computedPraemie(){
      let praemie
      if (this.contract.praemieZahlweiseBrutto && this.contract.praemieZahlweiseBrutto.toString().includes(',')){
        praemie = this.contract.praemieZahlweiseBrutto.replace(',','.')
      } else {
        praemie = this.contract.praemieZahlweiseBrutto
      }
      if (!praemie) return ''
      switch (this.contract.paymentType) {
      case 'MONTHLY':
        return praemie * 12
      case 'QUARTERLY':
        return praemie *  4
      case 'SEMI_ANUALLY':
        return praemie *  2
      case 'ANUALLY':
        return praemie
      default:
        return ''
      }
    }
  },
  async created(){
    await this.fetchData()
  },
  methods: {
    async fetchData(){
      if (this.pending) return
      this.pending = true
      try {
        // eslint-disable-next-line no-unused-vars
        const [ _, __, tenant ] = await Promise.all([
          this.$store.dispatch('assfinet-contract-companies/find'),
          this.$store.dispatch('assfinet-contract-kinds/find'),
          this.$store.dispatch('tenants/get', this.user.tenantId)
        ])
        this.tenant = tenant

        if (!this.tenant.customerContractsOptions){
          this.tenant.customerContractsOptions = { alwaysSendToAgent: false, alwaysSendToAgentText: 'Ihrem Makler zeigen?' }
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.pending = false
      }
    },
    getHumanPaymentType(apiPaymentType) {
      return this.$store.getters['enums/humanPaymentType'](apiPaymentType)
    },
    getHumanContractStatus(apiContractStatus) {
      return this.$store.getters['enums/humanContractStatus'](apiContractStatus)
    },
    async upload(event) {
      Array.from(event.target.files).map(async toUploadFile => {
        try {
          const file = await feathers.service('file').create({
            name: toUploadFile.name,
            mimeType: toUploadFile.type
          })
          await axios.put(file.uploadUrl, toUploadFile)

          this.contract.s3files.push(file)
          this.contract.s3fileIds.push(file._id)
        } catch (error) {
          console.error(error)
        }
      })
    },
    removeFile(file){
      const indexFile = this.contract.s3files.indexOf(file)
      const indexId = this.contract.s3fileIds.indexOf(file._id)
      this.contract.s3files.splice(indexFile, 1)
      this.contract.s3fileIds.splice(indexId, 1)
    },
    //Event Handler ListFilterModal
    choiceHandler(listFilterReturnValue){
      if (listFilterReturnValue.sender === 'contractCompany') this.setCompany(listFilterReturnValue.result)
      else if (listFilterReturnValue.sender === 'contractKind') this.setKind(listFilterReturnValue.result)
    },
    setCompany(item){
      this.contractCompany = item.name
      this.contract.contractCompany = item
    },
    setKind(item){
      this.contractKind = item.name
      this.contract.contractKind = item
    },
    async addContract(value, { resetForm }) {
      //format values
      if (this.contract.praemieZahlweiseBrutto && this.contract.praemieZahlweiseBrutto.includes(',')){
        this.contract.praemieZahlweiseBrutto = this.contract.praemieZahlweiseBrutto.replace(',','.')
      }

      //computed values
      if (this.visibleForAgent) this.contract.tenantId = this.user.tenantId
      this.contract.jahrespraemieBrutto = this.computedPraemie

      const loading = await loadingController.create({
        message: 'Lade Daten'
      })
      await loading.present()

      try {
        let newContract = await this.contract.save()
        //reset

        // Vorzeitige Validierung verhindern, falls ein zweiter Vertrag angelegt wird.
        this.contractCompany = undefined
        this.contractKind = undefined


        await this.$store.dispatch('sync-start/get', {
          async: true,
          contracts: true,
          contractId: newContract._id,
          processes: false
        })

        let contract = await this.$store.dispatch('contracts/get', newContract._id)
        await this.$store.dispatch('contracts/patch', [ contract._id, {
          origin: 'CUSTOMER',
          currency: 'EUR',
          praemieZahlweiseBrutto: this.contract.praemieZahlweiseBrutto?.replace(',','.')
        }])
        resetForm() //Form Leeren
        this.contract = new models.api.ContractOwnCreate({
          status: 'FREMD',
          visibleForTenant: true,
          paymentType: 'MONTHLY',
          s3files: [],
          s3fileIds: [],
        }),
        this.openToast()

        this.$router.push({ name: 'ContractDetail', params: { id: contract._id } })
      } catch (error) {
        console.error(error)
        const alert = await alertController.create({
          header: 'Fehler',
          message: error.message,
          buttons: [ 'Okay' ]
        })
        alert.present()
      } finally {
        loading.dismiss()
      }

    },
    async openToast(){
      const toast = await toastController
        .create({
          message: 'Vertrag erfolgreich gespeichert',
          duration: 4000,
          mode: 'ios',
          cssClass: 'transform -translate-y-16',
        })
      return toast.present()
    },
  }
}
</script>
