<template>
  <div>
    <h4 class="my-2">
      Art des Schadens und Schadenumfang
    </h4>
    <basic-input
      id="offender"
      v-model="localValue.offender"
      class="mt-4"
      label="Verursacher"
    />
    <basic-input
      id="offenderAddress"
      v-model="localValue.offenderAddress"
      class="mt-4"
      label="Anschrift"
    />
    <div class="mt-4">
      <BasicCheckbox
        id="customerAtFault"
        v-model="localValue.customerAtFault"
        name="customerAtFault"
        label="Trifft Sie ein Verschulden?"
      />
    </div>
    <basic-input
      v-if="localValue.customerAtFault"
      id="faultReason"
      v-model="localValue.faultReason"
      class="mt-4"
      label="Warum?"
    />
    <div class="mt-4">
      <BasicCheckbox
        id="claimsAgainstCustomer"
        v-model="localValue.claimsAgainstCustomer"
        name="claimsAgainstCustomer"
        label="Sind bereits Ansprüche gegen Sie erhoben worden?"
      />
    </div>
    <basic-input
      v-if="localValue.claimsAgainstCustomer"
      id="claimValue"
      v-model="localValue.claimValue"
      class="mt-4"
      label="Wie hoch?"
    />
    <div class="mt-4">
      <BasicCheckbox
        id="causedByJointVenture"
        v-model="localValue.causedByJointVenture"
        name="causedByJointVenture"
        label="Wurde der Schaden durch eine Arbeitsgemeinschaft verursacht?"
      />
    </div>
    <div class="mt-4">
      <BasicCheckbox
        id="recordedByPolice"
        v-model="localValue.recordedByPolice"
        name="recordedByPolice"
        label="Wurde der Fall Polizeilich aufgenommen?"
      />
    </div>
    <basic-input
      v-if="localValue.recordedByPolice"
      id="policeStation"
      v-model="localValue.policeStation"
      class="mt-4"
      label="Polizeidienststelle"
    />
    <basic-input
      v-if="localValue.recordedByPolice"
      id="diaryNumber"
      v-model="localValue.diaryNumber"
      class="mt-4"
      label="Tagebuch-Nr."
    />
    <div class="mt-4">
      <BasicCheckbox
        id="witness"
        v-model="localValue.witness"
        name="witness"
        label="Gibt es Zeugen?"
      />
    </div>
    <basic-input
      v-if="localValue.witness"
      id="witnessContact"
      v-model="localValue.witnessContact"
      class="mt-4"
      label="Name und Anschrift"
    />
    <h5 class="my-4">
      Anspruchsteller
    </h5>
    <basic-input
      id="claimantSurname"
      v-model="localValue.claimantSurname"
      class="mt-4"
      label="Vorname"
    />
    <basic-input
      id="claimantName"
      v-model="localValue.claimantName"
      class="mt-4"
      label="Nachname"
    />
    <basic-input
      id="claimantContactPerson"
      v-model="localValue.claimantContactPerson"
      class="mt-4"
      label="Name des Ansprechpartners/Firma"
    />
    <basic-input
      id="claimantStreet"
      v-model="localValue.claimantStreet"
      class="mt-4"
      label="Straße"
    />
    <basic-input
      id="claimantZipCode"
      v-model="localValue.claimantZipCode"
      class="mt-4"
      label="Postleitzahl"
    />
    <basic-input
      id="claimantTown"
      v-model="localValue.claimantTown"
      class="mt-4"
      label="Ort"
    />
    <basic-input
      id="claimantPhone"
      v-model="localValue.claimantPhone"
      class="mt-4"
      label="Telefon"
    />
    <div class="mt-4">
      <BasicCheckbox
        id="claimantEmployed"
        v-model="localValue.claimantEmployed"
        name="claimantEmployed"
        label="Ist der Anspruchsteller bei Ihnen beschäftigt?"
      />
    </div>
    <div class="mt-4">
      <BasicCheckbox
        id="claimantRelated"
        v-model="localValue.claimantRelated"
        name="claimantRelated"
        label="Sind die verwandt?"
      />
    </div>
    <h5 class="my-4">
      Sachschäden
    </h5>
    <basic-input
      id="damagedItems"
      v-model="localValue.damagedItems"
      class="mt-4"
      label="Was wurde beschädigt?"
    />
    <basic-input
      id="inspectedText"
      v-model="localValue.inspectedText"
      class="mt-4"
      label="Art und Umfang der Beschädigung"
    />
    <div class="mt-4">
      <BasicCheckbox
        id="inspected"
        v-model="localValue.inspected"
        name="inspected"
        label="Haben Sie den Schaden besichtigt"
      />
    </div>
    <div class="mt-4">
      <BasicInput
        id="damagedItemsPurpose"
        v-model="localValue.damagedItemsPurpose"
        name="damagedItemsPurpose"
        as="select"
        role="required"
        label="Waren die beschädigten Sachen…"
      >
        <option
          value="0"
          disabled
          selected
        >
          bitte wählen
        </option>
        <option :value="1">
          gemietet
        </option>
        <option :value="2">
          geliehen
        </option>
        <option :value="3">
          zu bearbeiten
        </option>
        <option :value="4">
          zu reparieren
        </option>
        <option :value="5">
          zu befördern
        </option>
        <option :value="6">
          in Verwahrung
        </option>
        <option :value="7">
          Sonstiges
        </option>
      </BasicInput>
    </div>
    <h5 class="my-4">
      Personschäden
    </h5>
    <basic-input
      id="injuries"
      v-model="localValue.injuries"
      class="mt-4"
      label="Welche Verletzungen sind eingetreten?"
    />
    <div class="mt-4">
      <BasicCheckbox
        id="workAccident"
        v-model="localValue.workAccident"
        name="workAccident"
        label="Liegt ein Arbeitsunfall vor?"
      />
    </div>
    <basic-input
      id="meldungBerufsgenossenschaft"
      v-model="localValue.meldungBerufsgenossenschaft"
      class="mt-4"
      label="Welcher Berufsgenossenschaft wurde der Unfall gemeldet?"
    />
    <div class="mt-4">
      <BasicInput
        id="numberInjuredPersons"
        v-model="localValue.numberInjuredPersons"
        name="numberInjuredPersons"
        as="select"
        rule="required"
        label="Anzahl der verletzten Personen"
      >
        <option
          value="0"
          disabled
          selected
        >
          bitte wählen
        </option>
        <option :value="0">
          0
        </option>
        <option :value="1">
          1
        </option>
        <option :value="2">
          2
        </option>
        <option :value="3">
          3
        </option>
        <option :value="4">
          4
        </option>
      </BasicInput>
    </div>
    <div
      v-for="(injuredPerson, index) in localValue.injuredPersons"
      :key="index"
    >
      <template v-if="index < localValue.numberInjuredPersons">
        <h5 class="my-4">
          Person {{ index+1 }}
        </h5>
        <basic-input
          :id="`geschaedigtePersonName${index}`"
          v-model="injuredPerson.surname"
          class="mt-4"
          label="Name"
        />
        <basic-input
          :id="`geschaedigtePersonVorname${index}`"
          v-model="injuredPerson.name"
          class="mt-4"
          label="Vorname"
        />
        <basic-input
          :id="`geschaedigtePersonFamilie${index}`"
          v-model="injuredPerson.family"
          class="mt-4"
          label="Famielienstand"
        />
        <basic-input
          :id="`Beruf${index}`"
          v-model="injuredPerson.profession"
          class="mt-4"
          label="Beruf"
        />
      </template>
    </div>
  </div>
</template>
<script>
import { reactive, toRef, watch } from 'vue'

import BasicInput from '../../../components/BasicInput.vue'
import BasicCheckbox from '../../../components/BasicCheckbox.vue'

export default {
  components: { BasicInput, BasicCheckbox },
  props: {
    modelValue: {
      type: Object,
      default: () => {}
    }
  },
  emits: [ 'update:modelValue' ],
  setup(props, { emit }) {
    const modelValue = toRef(props, 'modelValue')
    const localValue = reactive({ ...modelValue.value })

    watch(() => localValue, (val) => emit('update:modelValue', val), { deep: true })

    return {
      localValue
    }
  }
}
</script>
