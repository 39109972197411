import feathersClient, { makeServicePlugin, BaseModel } from '../../feathers-client'

class DamageReport extends BaseModel {
  constructor(data, options) {
    super(data, options)
  }

  static instanceDefaults() {
    return {
      contractId: null,
      userId: null,
      damage: {
        contractBranch: null,
        confirmation: false,
        general: {
          files: [],
          date: null,
          time: null,
          location: '',
          amount: 0,
          tenantNumber: '',
          ownVN: '',
          nameOrCompany: '',
          contactPerson: '',
          phone: '',
          customerStreet: '',
          customerZipCode: '',
          customerCity: '',
          maklerFuehrtVersSteuerAb: false,
          title: '',
          description: '',
          attachmentsComplete: false,
          attachmentsPartially: false,
          attachmentsFollow: false,
          imagesAttached: false
        },
        default: {
          recordedByPolice: false,
          policeStation: '',
          diaryNumber: '',
          witness: false,
          witnessContact: '',

        },
        haftpflicht: {
          offender: '',
          offenderAddress: '',
          customerAtFault: false,
          faultReason: '',
          claimsAgainstCustomer: false,
          claimValue: 0,
          causedByJointVenture: false,
          recordedByPolice: false,
          policeStation: '',
          diaryNumber: '',
          witness: false,
          witnessContact: '',
          claimantSurname: '',
          claimantName: '',
          claimantContactPerson: '',
          claimantStreet: '',
          claimantZipCode: '',
          claimantTown: '',
          claimantPhone: '',
          claimantEmployed: false,
          claimantRelated: false,
          damagedItems: '',
          inspectedText: '',
          inspected: false,
          damagedItemsPurpose: 0,
          injuries: '',
          workAccident: false,
          meldungBerufsgenossenschaft: '',
          numberInjuredPersons: 0,
          injuredPersons: Array.from(Array(4).keys()).map(() => {
            return {
              name: '',
              surname: '',
              family: '',
              profession: ''
            }
          })
        },
        car: {
          haftpflichtSchaden: false,
          kaskoSchaden: false,
          driverName: '',
          driverBirthDate: '',
          driverStreet: '',
          driverZipCode: '',
          driverTown: '',
          driverLicenseClass: '',
          driverLicenseDate: '',
          driverLicenseId: '',
          driverLicenseAuthority: '',
          driverLicenseValid: false,
          driverLicenseWithheld: false,
          licensePlate: '',
          registrationDate: '',
          type: '',
          manufacturer: '',
          chassisNumber: '',
          itemsInsuredElsewhere: false,
          otherInsurance: '',
          leasedOrBankFinanced: false,
          institute: '',
          connectedToTrailer: false,
          trailerLicensePlate: '',
          trailerInsuranceContractId: '',
          trailerInsuranceCompany: '',
          accidentCause: 0,
          accidentResponsibility: 0,
          recordedByPolice: false,
          policeStation: '',
          diaryNumber: '',
          driverDrunk: false,
          opponentDrunk: false,
          driverBloodtest: false,
          opponentBloodtest: false,
          itemsStolen: '',
          howSecured: '',
          keyLocation: '',
          ClosedDoorsWindows: ''
        },
        rs: {
          offender: '',
          witness: false,
          witnessData: '',
          recordedByPolice: false,
          policeStation: '',
          diaryNumber: ''
        },
        sach: {
          damageType: 0,
          itemsInsuredElsewhere: false,
          damageEntryCount: 0,
          damageEntries: Array.from(Array(4).keys()).map(() => {
            return {
              floor: '',
              room: '',
              affectedItems: '',
              repairPossible: false
            }
          }),
          policeStation: '',
          diaryNumber: '',
          stolenGoodsListAttached: false,
          damageReason: 0,
          damagePipe: 0,
          damagePipeLocation: 0,
          stormDamage: false
        },
        bau: {
          bauTyp: 0
        },
        bank: {
          dataFromContract: false,
          accountHolder: '',
          bankName: '',
          iban: '',
          bic: ''
        }
      }
    }
  }
}

DamageReport.modelName = 'DamageReport'

const servicePath = 'damage-report'
const servicePlugin = makeServicePlugin({
  idField: '_id',
  Model: DamageReport,
  service: feathersClient.service(servicePath),
  servicePath,
  handleEvents: {
    updated: (_item, { model }) => {
      model.store.commit('setDamageReportSubmitStatus', _item.status)
    }
  }
})

feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
})

export default servicePlugin
