import { createStore } from 'vuex'

import auth from './store.auth'

const requireModule = require.context(
  // The path where the service modules live
  './services',
  // Whether to look in subfolders
  false,
  // Only include .js files (prevents duplicate imports`)
  /\.js$/
)
const servicePlugins = requireModule
  .keys()
  .map(modulePath => requireModule(modulePath).default)

// Create a new store instance.
export default createStore({
  state() {
    return {
    }
  },
  mutations: {
    setEnums(state, enums) {
      state.enums = enums
    },
    setContractsUpdating(state, isUpdating) {
      state.contractsUpdating = isUpdating
    },
    setDamageReportSubmitStatus(state, status) {
      state.damageReportSubmitStatus = status
    },
    setVersion(state, version) {
      state.version = version
    }
  },
  getters: {
    paymentTypesEnum: state => {
      return state.enums['payment-types']
    },
    originsEnum: state => {
      return state.enums['origins']
    },
    rolesEnum: state => {
      return state.enums['roles']
    },
    contractsUpdating: state => {
      return state.contractsUpdating
    },
    damageReportSubmitStatus: state => {
      return state.damageReportSubmitStatus
    }
  },
  plugins: [ ...servicePlugins, auth ]
})

