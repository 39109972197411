import feathersClient, { makeServicePlugin, BaseModel } from '../../feathers-client'

class ContractOwnCreate extends BaseModel {
  constructor(data, options) {
    super(data, options)
  }

  static instanceDefaults() {
    return {}
  }
}

ContractOwnCreate.modelName = 'ContractOwnCreate'

const servicePath = 'contract-own-create'
const servicePlugin = makeServicePlugin({
  idField: '_id',
  Model: ContractOwnCreate,
  service: feathersClient.service(servicePath),
  servicePath
})

feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
})

export default servicePlugin
