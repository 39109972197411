import dayjs from 'dayjs'

export default async function(value, date){
  const laterDate = new Date(value)
  const earlierDate = new Date(date)

  if (earlierDate < laterDate) {
    return true
  }
  return `Datum muss später sein als ${dayjs(earlierDate).format('DD.MM.YYYY')} `
}
