<template>
  <IonPage>
    <IonContent>
      <div class="bg-primary text-white rounded-b-md ion-padding">
        <div class="text-center my-10">
          <h3 class="mb-3">
            Guten Tag
          </h3>
          <h5 class="mb-4">
            {{ user.firstname }}
            {{ user.lastname }}
          </h5>
          <div class="text-gray-100">
            <p>
              <FontAwesomeIcon
                size="sm"
                :icon="faEnvelope"
              />
              {{ user.email }}
            </p>
            <p v-if="user && user.birthday">
              <FontAwesomeIcon
                size="sm"
                :icon="faBirthdayCake"
              />
              {{ dayjs(user.birthday).format('DD.MM.YYYY') }}
            </p>
          </div>
        </div>
      </div>
      <div class="ion-padding mt-6">
        <h4 class="mb-4">
          Bearbeiten Sie Ihre Daten
        </h4>
        <div
          class="rounded-md bg-gray-100 dark:bg-gray-800 flex items-center p-5 mb-5"
          @click="$router.push({ name: 'ProfilePersonalData' })"
        >
          <div class="w-auto pr-4">
            <div class="icon-circles">
              <FontAwesomeIcon
                size="lg"
                :icon="faUser"
              />
            </div>
          </div>
          <div class="w-full">
            <h6 class="mb-0">
              Persönliche Informationen bearbeiten
            </h6>
          </div>
        </div>
        <div
          v-if="tenant && !tenant?.disableThirdPartyContracts"
          class="rounded-md bg-gray-100 dark:bg-gray-800 flex items-center p-5 mb-5"
          @click="$router.push({ name: 'ContractCreate' })"
        >
          <div class="w-auto pr-4">
            <div class="icon-circles">
              <FontAwesomeIcon
                size="lg"
                :icon="faFilePlus"
              />
            </div>
          </div>
          <div class="w-full">
            <h6 class="mb-0">
              Eigenen Vertrag hinzufügen
            </h6>
          </div>
        </div>
        <!-- <div
          class="rounded-md bg-gray-100 dark:bg-gray-800 flex items-center p-5 mb-5"
          @click="$router.push({ name: 'ProfileLoginData'})"
        >
          <div class="w-auto pr-4">
            <div class="icon-circles">
              <FontAwesomeIcon
                size="lg"
                :icon="faLock"
              />
            </div>
          </div>
          <div class="w-full">
            <h6 class="mb-0">
              Logindaten bearbeiten
            </h6>
          </div>
        </div> -->
        <!-- <div
          class="rounded-md bg-gray-100 dark:bg-gray-800 flex items-center p-5 mb-5"
          @click="$router.push({ name: 'ProfileAssfinetData'})"
        >
          <div class="w-auto pr-4">
            <div class="icon-circles">
              <FontAwesomeIcon
                size="lg"
                :icon="faEye"
              />
            </div>
          </div>
          <div class="w-full">
            <h6 class="mb-0">
              Assfinet Zugangsdaten bearbeiten
            </h6>
          </div>
        </div> -->
        <div
          class="rounded-md bg-gray-100 dark:bg-gray-800 flex items-center px-5 py-4 mb-5"
          @click="logout"
        >
          <div class="w-full">
            <h6 class="mb-0 text-red-500 text-center">
              <FontAwesomeIcon
                :icon="faSignOut"
              />
              Abmelden
            </h6>
          </div>
        </div>
      </div>
      <div class="text-center text-gray-400 text-sm my-2">
        myDocuments v{{ $store.state.version }}
      </div>
    </IonContent>
  </IonPage>
</template>

<script>
import { faCog, faBirthdayCake, faEnvelope } from '@fortawesome/pro-duotone-svg-icons'
import { faUser, faLock, faEye, faSignOut, faFilePlus } from '@fortawesome/pro-solid-svg-icons'
import dayjs from 'dayjs'
import { IonPage, IonContent } from '@ionic/vue'
export default {
  components: {
    IonPage,
    IonContent
  },
  data: () => ({
    user: null,
    tenant: null,
    faCog,
    faBirthdayCake,
    faUser,
    faLock,
    faEye,
    faEnvelope,
    faSignOut,
    faFilePlus,
    dayjs
  }),
  watch: {
    $route: {
      handler: 'fetchData',
      immediate: true
    }
  },
  methods: {
    async fetchData() {
      this.user = this.$store.getters['auth/user']
      this.tenant = this.$store.getters['tenants/get'](this.user.tenantId)
    },
    async logout(){
      await this.$store.dispatch('auth/logout')
      this.$router.go({ name: 'Login' })
    }
  }
}

</script>

<style lang="scss" scoped>
.icon-circles{
  @apply rounded-full h-12 w-12 flex items-center justify-center bg-white text-primary dark:bg-primary dark:text-white;
}
</style>
