<template>
  <IonPage>
    <IonHeader class="ion-no-border">
      <IonToolbar mode="ios">
        <IonButtons slot="start">
          <IonBackButton
            mode="md"
            class="text-primary dark:text-white"
          />
        </IonButtons>
        <IonTitle>
          Daten bearbeiten
        </IonTitle>
      </IonToolbar>
    </IonHeader>
    <IonContent class="ion-padding">
      <h4 class="mt-2">
        Assfinet Zugangsdaten
      </h4>
      <IonToast
        :is-open="open"
        :duration="2000"
        message="Speichern erfolgreich"
        mode="ios"
      />
      <div class="mt-4">
        <div class="mt-1">
          <BasicInput
            id="email"
            v-model="user.email"
            type="email"
            name="email"
            label="Email"
            label-class="block text-sm font-medium text-gray-700 dark:text-gray-500"
            placeholder="you@example.com"
          />
        </div>
      </div>
      <div class="mt-4">
        <div class="mt-1">
          <BasicInput
            id="password"
            v-model="password"
            type="password"
            name="password"
            label="Passwort"
            label-class="block text-sm font-medium text-gray-700 dark:text-gray-500"
            placeholder="*********"
          />
        </div>
      </div>
      <div class="mt-4">
        <div class="mt-1">
          <BasicInput
            id="confirmPassword"
            v-model="confirmpassword"
            type="password"
            name="confirmPassword"
            label-class="block text-sm font-medium text-gray-700 dark:text-gray-500"
            label="Passwort bestätigen"
            placeholder="*********"
          />
        </div>
      </div>
      <div class="text-right">
        <button
          class="btn bg-primary text-white mt-4"
          @click="save"
        >
          Speichern
        </button>
      </div>
    </IonContent>
  </IonPage>
</template>
<script>
import { faCheck } from '@fortawesome/pro-solid-svg-icons'
import { IonPage, IonToast, IonContent, IonHeader, IonToolbar, IonButtons, IonBackButton, IonTitle } from '@ionic/vue'
import BasicInput from '../../components/BasicInput.vue'
export default {
  components: {
    IonPage,
    IonContent,
    IonToast,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonTitle,
    BasicInput
  },
  data: () => ({
    faCheck,
    open: false,
    password: null,
    confirmpassword: null
  }),
  computed: {
    user() {
      return this.$store.getters['auth/user']
    }
  },
  methods: {
    async save(){
      if (this.password !== '' && this.password !== null) {
        if (this.password == this.confirmpassword) {
          this.user.password = this.password
        }
      }
      await this.user.save()
      this.setOpen(true)
    },
    setOpen(value){
      this.open = value
    },
  }
}
</script>
