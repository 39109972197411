import { defineRule, configure } from 'vee-validate'
import { required, min_value, confirmed } from '@vee-validate/rules'
import { localize, setLocale  } from '@vee-validate/i18n'
import passwordStrength from './password-strength'
import disabledBirthdays from './disabled-birthdays'
import disabledFutureDates from './disabled-future-dates'
import disabledFutureTimes from './disabled-future-time'
import laterDate from './later-date'

import de from '@vee-validate/i18n/dist/locale/de.json'

defineRule('required', required)
defineRule('min_value', min_value)
defineRule('password-strength', passwordStrength)
defineRule('confirmed', confirmed)
defineRule('disabled-birthdays', disabledBirthdays)
defineRule('disabled-future-dates', disabledFutureDates)
defineRule('disabled-future-time', disabledFutureTimes)
defineRule('later-date', laterDate)

configure({
  generateMessage: localize({ de })
})

setLocale('de')
