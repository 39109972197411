/*
All StatusBar methods returning a promise should implement this function as the error callback.
Because a web browser has no status bar, every StatusBar method will throw an error if called.
This function checks if the thrown error has exactly this cause. If it hasn't, rethrow the error.
*/
import * as Sentry from '@sentry/vue'

export default function(message) {
  if (message !== 'StatusBar does not have web implementation.' && message !== 'Error: not implemented') {
    Sentry.captureException(message)
  }
}
