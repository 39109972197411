<template>
  <div @click="toggleProcess()">
    <div class="flex items-center">
      <p class="font-bold mb-0 flex-grow">
        Neue Nachricht - {{ process.title }}
      </p>
      <FontAwesomeIcon
        v-if="collapsed"
        :icon="faAngleLeft"
      />
      <FontAwesomeIcon
        v-if="!collapsed"
        :icon="faAngleDown"
      />
    </div>
    <Collapse :collapsed="collapsed">
      <div
        v-for="(chatAction, chatActionIndex) in process.chatActions"
        :key="`chatAction-${chatActionIndex}`"
      >
        <p class="text-gray-500 text-sm">
          Letzte Nachricht von {{ chatAction.creator }} am {{ dayjs(chatAction.createdAt).format('DD.MM.YYYY') }}
        </p>
        <p>
          {{ chatAction.text }}
        </p>
      </div>
      <div
        v-for="(file, fileIndex) in process.files"
        :key="`file-${fileIndex}`"
        class="btn btn-link text-primary dark:bg-primary dark:text-white px-4 text-left w-full flex mt-2"
        @click="openLinkHandler(file)"
        @click.stop=""
      >
        <FontAwesomeIcon
          :icon="faFilePdf"
          class="self-center mb-1"
        />
        <span class="overflow-hidden overflow-ellipsis whitespace-nowrap ml-1">
          {{ file.name }}
        </span>
      </div>
    </Collapse>
  </div>
</template>

<script>
import Collapse from '@/components/Collapse.vue'
import { faFilePdf } from '@fortawesome/pro-duotone-svg-icons'
import { faAngleLeft, faAngleDown } from '@fortawesome/pro-solid-svg-icons'
import dayjs from 'dayjs'
import openLink  from '@/utilities/openLink'
export default {
  components: {
    Collapse
  },
  props: {
    process: {
      type: Object,
      default: undefined
    }
  },
  data() {
    return {
      collapsed: true,
      host: process.env.VUE_APP_API_HOST,
      faFilePdf,
      faAngleLeft,
      faAngleDown,
      dayjs
    }
  },
  computed: {
    jwt() {
      return this.$store.state.auth.accessToken
    }
  },
  methods: {
    openLink,
    toggleProcess() {
      this.collapsed = !this.collapsed
    },
    openLinkHandler(file) {
      if (file.originalContentType === 'application/pdf' || file.originalContentType === 'image/png' || file.originalContentType === 'image/jpg' || file.originalContentType === 'image/jpeg') {
        this.openLink(`${this.host}api/contracts/${this.contract._id}/attachments/${file.data.Id}?jwt=${this.jwt}`)
      } else {
        this.openLink(`${this.host}api/contracts/${this.contract._id}/attachments/${file.data.Id}?jwt=${this.jwt}`, 'external')
      }
    }
  }
}
</script>
