import feathersClient, { makeServicePlugin, BaseModel } from '../../feathers-client'

class Enum extends BaseModel {
  constructor(data, options) {
    super(data, options)
  }

  static instanceDefaults() {
    return {
    }
  }
}

Enum.modelName = 'Enum'

const servicePath = 'enums'
const servicePlugin = makeServicePlugin({
  idField: '_id',
  Model: Enum,
  service: feathersClient.service(servicePath),
  servicePath,
  mutations: {
    setEnums(state, enums) {
      state.enums = enums
    }
  },
  actions: {
    afterFind(store, response) {
      if (response[0]) store.commit('setEnums', response[0])
    }
  },
  getters: {
    paymentTypes(state) {
      return state.enums.paymentTypes
    },
    humanPaymentType: (state) => (apiPaymentType) => {
      const paymentTypeValues = Object.values(state.enums.paymentTypes)
      let res = paymentTypeValues.find(type => type.api === apiPaymentType)
      return res.human || ''
    },
    contractStatus(state) {
      return state.enums.contractStatus
    },
    humanContractStatus: (state) => (contractStatus) => {
      const contractStatusValues = Object.values(state.enums.contractStatus)
      let res = contractStatusValues.find(status => status.api === contractStatus)
      return res.human || ''
    }
  }
})

feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
})

export default servicePlugin
