import feathersClient, { makeServicePlugin, BaseModel } from '../../feathers-client'

class Invitations extends BaseModel {
  constructor(data, options) {
    super(data, options)
  }

  static instanceDefaults() {
    return {}
  }
}

Invitations.modelName = 'Invitations'

const servicePath = 'invitations'
const servicePlugin = makeServicePlugin({
  Model: Invitations,
  service: feathersClient.service(servicePath),
  servicePath
})

feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
})

export default servicePlugin
