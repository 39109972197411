import store from '@/store/'

export default function(contractA, contractB) {
  if (getHumanContractStatus(contractA.status) === getHumanContractStatus(contractB.status)) {
    return contractA.contractKind.name > contractB.contractKind.name ? 1 : -1
  } else {
    return getHumanContractStatus('ACTIVE') === getHumanContractStatus(contractB.status) ? 1 : -1
  }
}

function getHumanContractStatus(apiContractStatus) {
  return store.getters['enums/humanContractStatus'](apiContractStatus)
}