<template>
  <div>
    <div class="mt-4">
      <BasicCheckbox
        id="recordedByPolice"
        v-model="localValue.recordedByPolice"
        name="recordedByPolice"
        label="Wurde der Fall Polizeilich aufgenommen?"
      />
    </div>

    <basic-input
      v-if="localValue.recordedByPolice"
      id="policeStation"
      v-model="localValue.policeStation"
      class="mt-4"
      label="Polizeidienststelle"
    />
    <basic-input
      v-if="localValue.recordedByPolice"
      id="diaryNumber"
      v-model="localValue.diaryNumber"
      class="mt-4"
      label="Tagebuch-Nr."
    />
    <div class="mt-4">
      <BasicCheckbox
        id="witness"
        v-model="localValue.witness"
        name="witness"
        label="Gibt es Zeugen?"
      />
    </div>
    <basic-input
      v-if="localValue.witness"
      id="witnessContact"
      v-model="localValue.witnessContact"
      class="mt-4"
      label="Name und Anschrift"
    />
  </div>
</template>
<script>
import { reactive, toRef, watch } from 'vue'

import BasicInput from '../../../components/BasicInput.vue'
import BasicCheckbox from '../../../components/BasicCheckbox.vue'

export default {
  components: { BasicInput, BasicCheckbox },
  props: {
    modelValue: {
      type: Object,
      default: () => {}
    }
  },
  emits: [ 'update:modelValue' ],
  setup(props, { emit }) {
    const modelValue = toRef(props, 'modelValue')
    const localValue = reactive({ ...modelValue.value })

    watch(() => localValue, (val) => emit('update:modelValue', val), { deep: true })

    return {
      localValue
    }
  }
}
</script>
