<template>
  <ActionSheetModal ref="actionSheetModal">
    <div class="bg-white dark:bg-gray-800 rounded-md ion-padding h-96 max-h-96 shadow-lg relative">
      <div class="h-full overflow-y-scroll">
        <div
          v-for="contract in contracts"
          :key="contract._id"
          class="flex items-center mb-5"
          @click="$router.push({ name: 'CreateDamageReport', params: { contractId: contract._id } }), hide()"
        >
          <div class="w-auto">
            <img
              v-if="contract.contractCompany?.logo"
              :src="contract.contractCompany.logo.downloadUrl"
              alt=""
              class="rounded shadow w-10 h-10 object-contain object-center bg-white"
            >
            <img
              v-if="!contract.contractCompany?.logo"
              src="@/assets/default-logo.jpg"
              class="rounded shadow w-10 h-10 object-contain object-center bg-white"
            >
          </div>
          <div class="w-full px-4">
            <h6 class="leading-4 dark:text-white mb-0">
              {{ contract.contractKind.name }} - {{ contract.number }}
            </h6>
            <p class="text-sm text-gray-400 mb-0">
              Beginn: {{ dayjs(contract.startDate).format('DD.MM.YYYY') }}, {{ formatNumber(contract.praemieZahlweiseBrutto) }}{{ formatCurrency(contract.currency) }} {{ getHumanPaymentType(contract.paymentType) }}
            </p>
          </div>
          <div class="w-auto text-primary dark:text-white">
            <FontAwesomeIcon
              :icon="faAngleRight"
            />
          </div>
        </div>
      </div>
    </div>
    <button
      class="btn block w-full bg-white text-primary font-bold text-center dark:bg-gray-800 dark:text-white rounded-md ion-padding shadow-lg mt-4 py-4"
      @click="hide()"
    >
      Abbrechen
    </button>
  </ActionSheetModal>
</template>

<script>
import { alertController, loadingController } from '@ionic/vue'
import dayjs from 'dayjs'
import { faAngleRight } from '@fortawesome/pro-solid-svg-icons'
import * as Sentry from '@sentry/vue'
import formatCurrency from '@/utilities/filters/formatCurrency'
import formatNumber from '@/utilities/filters/formatNumber'
import ActionSheetModal from '../../../components/ActionSheetModal.vue'
import contractSort from '@/utilities/sorts/contractSort'

export default {
  components: {
    ActionSheetModal,
  },
  data: () => ({
    open: false,
    faAngleRight,
    loading: undefined,
    dayjs
  }),
  ionViewWillEnter() {
    this.fetchData()
  },
  computed: {
    contracts() {
      let list = this.$store.getters['contracts/list'].filter(contract => contract.origin === 'ASSFINET')
      return list.sort(contractSort)
    }
  },
  methods: {
    formatCurrency,
    formatNumber,
    async fetchData() {
      const loading = await loadingController.create({
        message: 'Lade Daten'
      })
      await loading.present()
      try {
        this.contract = await this.$store.dispatch('contracts/find')
      } catch (error) {
        Sentry.captureException(error)
        const alert = await alertController.create({
          header: 'Fehler',
          message: error.message,
          buttons: [ 'Okay' ]
        })
        alert.present()
      } finally {
        loading.dismiss()
      }
    },
    hide(){
      this.$refs.actionSheetModal.hide()
    },
    show(){
      this.$refs.actionSheetModal.show()
    },
    getHumanPaymentType(apiPaymentType) {
      return this.$store.getters['enums/humanPaymentType'](apiPaymentType)
    },
    getHumanContractStatus(apiContractStatus) {
      return this.$store.getters['enums/humanContractStatus'](apiContractStatus)
    }
  }
}
</script>
