<template>
  <div
    class="action-sheet-modal h-screen w-screen relative"
    :class="{ 'hidden': !open }"
  >
    <div
      class="action-sheet-modal h-screen w-screen absolute top-0 bg-black transition"
      :class="{ 'bg-opacity-50': open, 'bg-opacity-0 hidden': !open }"
      @click.self="hide()"
    />
    <transition name="slide-fade">
      <div
        v-if="open"
        class="action-sheet-modal w-full ion-padding absolute bottom-0"
      >
        <slot />
      </div>
    </transition>
  </div>
</template>

<script>
import { faAngleRight } from '@fortawesome/pro-solid-svg-icons'
export default {
  data: () => ({
    faAngleRight,
    open: false
  }),
  methods: {
    show(){
      this.open = true
    },
    hide(){
      this.open = false
    }
  }
}
</script>

<style lang="scss" scoped>
  .action-sheet-modal{
    z-index: 200;
  }
  .slide-fade-enter-active, .slide-fade-leave-active {
    transition: all .3s ease;
  }
  .slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }
</style>