export default async function(value){
  const today = new Date()
  const tomorrow = new Date(today)
  tomorrow.setDate(tomorrow.getDate()+1)
  tomorrow.setHours(0,0,0,0)
  const valueDate = new Date(value)
  if (valueDate < tomorrow){
    return true
  }
  return 'Kein gültiges Datum'
}