
export default function(value) {
  if (!value) {
    return value
  }
  switch (value) {
  case 'EUR':
    return '€'
  default:
    return ''
  }
}
