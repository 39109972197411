<template>
  <IonPage>
    <IonContent>
      <IonRefresher
        id="refresher"
        slot="fixed"
        @ionRefresh="syncContracts($event)"
      >
        <IonRefresherContent
          pulling-text="Ziehe zum Syncronisieren"
          refreshing-spinner="circles"
          refreshing-text="Lade Verträge neu..."
        />
      </IonRefresher>
      <div class="ion-padding">
        <h3 class="mt-5">
          Ihre Verträge
        </h3>
        <!-- Add Contract Button-->
        <div
          v-if="tenant && !tenant?.disableThirdPartyContracts"
          class="p-2 px-3 bg-gray-100 dark:bg-gray-800 rounded mb-5 flex items-center"
          @click="$router.push({ name: 'ContractCreate' })"
        >
          <div class="w-full">
            <p class="mb-0 ml-1">
              <span class="text-primary dark:text-white mr-1">
                <FontAwesomeIcon
                  :icon="faFilePlus"
                />
              </span>
              Eigenen Vertrag hinzufügen
            </p>
          </div>
          <div class="w-auto text-primary dark:text-white">
            <FontAwesomeIcon
              :icon="faAngleRight"
              size="lg"
            />
          </div>
        </div>
        <!-- Add Contract Button-->

        <div class="list-wrapper">
          <ListItem
            v-for="contract of contractListFirst"
            :key="contract._id"
            :contract="contract"
            @click="$router.push({ name: 'ContractDetail', params: { id: contract._id } })"
          />
          <div
            v-if="tenant && tenant.callToAction && tenant.callToAction.contractList && tenant.callToAction.contractList.title && tenant.callToAction.contractList.description"
            class="p-4 bg-primary rounded-md text-white mb-10 flex items-center"
            @click="openLink(tenant.callToAction.contractList.link)"
          >
            <div class="w-full">
              <h5>
                <FontAwesomeIcon
                  :icon="faLifeRing"
                /> {{ tenant.callToAction.contractList.title }}
              </h5>
              <p class="mb-0">
                {{ tenant.callToAction.contractList.description }}
              </p>
            </div>
            <div class="w-auto">
              <FontAwesomeIcon
                :icon="faAngleRight"
                size="3x"
              />
            </div>
          </div>
          <ListItem
            v-for="contract of contractListLast"
            :key="contract._id"
            :contract="contract"
            @click="$router.push({ name: 'ContractDetail', params: { id: contract._id } })"
          />
        </div>
      </div>
    </IonContent>
  </IonPage>
</template>

<script>
import { chevronDownCircleOutline } from 'ionicons/icons'
import { faFilePlus, faLifeRing } from '@fortawesome/pro-duotone-svg-icons'
import { faAngleRight } from '@fortawesome/pro-regular-svg-icons'
import { IonPage, IonRefresherContent, IonRefresher, IonContent, alertController } from '@ionic/vue'
import * as Sentry from '@sentry/vue'
import ListItem from './components/ListItem.vue'
import contractSort from '@/utilities/sorts/contractSort'
import openLink from '@/utilities/openLink'

export default  {
  components: { IonRefresherContent, IonRefresher, IonContent, IonPage, ListItem },
  inject: [ 'openDamageReportSelectContractModal' ],
  data() {
    return {
      chevronDownCircleOutline,
      refreshEvent: null,
      faFilePlus,
      faAngleRight,
      faLifeRing
    }
  },
  computed: {
    contractList() {
      let list = this.$store.getters['contracts/find']({
        query: { parentContractId: null }
      }).data
      return list.sort(contractSort)
    },
    contractListFirst() {
      return this.contractList.slice(0, 2)
    },
    contractListLast() {
      return this.contractList.slice(2)
    },
    user(){
      return this.$store.getters['auth/user']
    },
    tenant() {
      const tenantId = this.user.tenantId
      return this.$store.getters['tenants/get'](tenantId)
    }
  },
  watch: {
    '$store.state.contractsUpdating': {
      handler(newVal) {
        if (newVal === false && this.refreshEvent) {
          this.refreshEvent.target.complete()
        }
      }
    }
  },
  methods: {
    openLink,
    async syncContracts(event) {
      try {
        this.$store.commit('setContractsUpdating', true)
        this.refreshEvent = event
        await this.$store.dispatch('sync-start/get', {
          contracts: true
        })
      } catch (error) {
        Sentry.captureException(error)
        const alert = await alertController.create({
          header: 'Fehler',
          message: error.message,
          buttons: [ 'Okay' ]
        })
        await alert.present()
        this.$store.commit('setContractsUpdating', false)
      }
    }
  }
}
</script>
