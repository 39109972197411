<template>
  <div>
    <div class="mt-4">
      <BasicInput
        id="damageType"
        v-model="localValue.damageType"
        as="select"
        rules="required"
        name="damageType"
        label="Art des Schadens"
      >
        <option
          value="0"
          disabled
          selected
        >
          bitte wählen
        </option>
        <option value="1">
          Feuer
        </option>
        <option value="2">
          Feuer-Betriebsunterbrechung
        </option>
        <option value="3">
          Einbruchdiebstahl
        </option>
        <option value="4">
          Leitungswasser
        </option>
        <option value="5">
          Sturm/Hagel
        </option>
        <option value="6">
          Sonstiges
        </option>
      </BasicInput>
    </div>
    <BasicCheckbox
      id="itemsInsuredElsewhere"
      v-model="localValue.itemsInsuredElsewhere"
      class="mt-4"
      label="Sind die vom Schaden betroffenen Sachen auch noch anderweitig versichert?"
    />

    <basic-input
      id="damageEntryCount"
      v-model="localValue.damageEntryCount"
      class="mt-4"
      as="select"
      rules="required"
      label="Schadenumfang"
    >
      <option
        value="0"
        disabled
        selected
      >
        bitte wählen
      </option>
      <option :value="0">
        0
      </option>
      <option :value="1">
        1
      </option>
      <option :value="2">
        2
      </option>
      <option :value="3">
        3
      </option>
      <option :value="4">
        4
      </option>
    </basic-input>

    <div
      v-for="(damageEntry, index) in localValue.damageEntries"
      :key="index"
    >
      <template v-if="localValue.damageEntryCount > index">
        <h5 class="my-4">
          Schaden {{ index+1 }}
        </h5>
        <basic-input
          :id="`floor${index}`"
          v-model="damageEntry.floor"
          class="mt-4"
          label="Welche Geschosse?"
        />
        <basic-input
          :id="`room${index}`"
          v-model="damageEntry.room"
          class="mt-4"
          label="Welche Räume?"
        />
        <basic-input
          :id="`affectedItems${index}`"
          v-model="damageEntry.affectedItems"
          class="mt-4"
          label="Betroffene Sachen"
        />
        <div class="mt-4">
          <BasicCheckbox
            :id="`repairPossible${index}`"
            v-model="damageEntry.repairPossible"
            :name="`repairPossible${index}`"
            label="Reparatur möglich?"
          />
        </div>
      </template>
    </div>
    <div v-if="localValue.damageType === '1' || localValue.damageType === '2' || localValue.damageType === '3'">
      <basic-input
        id="policeStation"
        v-model="localValue.policeStation"
        class="mt-4"
        label="Polizeidienststelle"
      />
      <basic-input
        id="diaryNumber"
        v-model="localValue.diaryNumber"
        class="mt-4"
        label="Aktenzeichen"
      />
      <div class="mt-4">
        <BasicCheckbox
          id="stolenGoodsListAttached"
          v-model="localValue.stolenGoodsListAttached"
          name="stolenGoodsListAttached"
          label="Stehlgutliste ist beigefügt"
        />
      </div>
      <div class="rounded-md bg-blue-50 p-3 my-4">
        <div class="flex">
          <div class="ml-3 flex-1 md:flex md:justify-between">
            <p class="text-sm text-blue-700">
              Bei Aktualisierung der Stehlgutliste sowohl die Polizei als auch uns informieren!
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="localValue.damageType === '4'"
      class="mt-4"
    >
      <BasicInput
        id="damageReason"
        v-model="localValue.damageReason"
        as="select"
        name="damageReason"
        rule="required"
        label="Was ist die Ursache?"
      >
        <option
          disabled
          selected
          value="0"
        >
          bitte wählen
        </option>
        <option value="0">
          Rohrbruch
        </option>
        <option value="1">
          offener Hahn
        </option>
        <option value="2">
          Überlaufen
        </option>
        <option value="3">
          Verstopfung
        </option>
        <option value="4">
          Frostschaden
        </option>
        <option value="5">
          Undichtwerden
        </option>
      </BasicInput>
    </div>
    <div
      v-if="localValue.damageType === '4' && localValue.damageReason === '0'"
      class="mt-4"
    >
      <BasicInput
        id="damagePipe"
        v-model="localValue.damagePipe"
        name="damagePipe"
        as="select"
        rule="required"
        label="Welche Leitung"
      >
        <option
          value="0"
          disabled
          selected
        >
          bitte wählen
        </option>
        <option value="0">
          Zuleitungsrohr
        </option>
        <option value="1">
          Abwasserleitung
        </option>
        <option value="2">
          Heizung
        </option>
        <option value="3">
          Regenrohr
        </option>
        <option value="4">
          Armaturen
        </option>
      </BasicInput>
    </div>
    <div
      v-if="localValue.damageType === '4' && localValue.damageReason === '0'"
      class="mt-4"
    >
      <BasicInput
        id="damagePipeLocation"
        v-model="localValue.damagePipeLocation"
        name="damagePipeLocation"
        as="select"
        rule="required"
        label="Wo ist das Rohr verlegt?"
      >
        <option
          value="0"
          disabled
          selected
        >
          bitte wählen
        </option>
        <option value="0">
          innerhalb des Gebäudes
        </option>
        <option value="1">
          außerhalb des Gebäudes
        </option>
        <option value="2">
          im Erdbereich unterhalb des Gebäudes
        </option>
      </BasicInput>
    </div>

    <div
      v-if="localValue.damageType === '5'"
      class="mt-4"
    >
      <BasicCheckbox
        id="stormDamage"
        v-model="localValue.stormDamage"
        name="stormDamage"
        label="Sind auch in der näheren Umgebung des Versicherungsgrundstückes Sturmschäden entstanden?"
      />
    </div>
  </div>
</template>
<script>
import { reactive, toRef, watch } from 'vue'

import BasicInput from '../../../components/BasicInput.vue'
import BasicCheckbox from '../../../components/BasicCheckbox.vue'

export default {
  components: { BasicInput, BasicCheckbox },
  props: {
    modelValue: {
      type: Object,
      default: () => {}
    }
  },
  emits: [ 'update:modelValue' ],
  setup(props, { emit }) {
    const modelValue = toRef(props, 'modelValue')
    const localValue = reactive({ ...modelValue.value })

    watch(() => localValue, (val) => emit('update:modelValue', val), { deep: true })

    return {
      localValue
    }
  }
}
</script>
