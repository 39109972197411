import { computed, toRef, watch } from '@vue/runtime-core'
import { useField } from 'vee-validate'
export default function useValidateModel(props, emit) {
  const fieldName = props.name || props.label
  const fieldId = props.id || fieldName
  const fieldRules = toRef(props, 'rules')
  const { errorMessage, handleChange, meta } = useField(fieldId, fieldRules, { initialValue: props.modelValue, label: fieldName })
  const model = computed({
    get() {
      return props.modelValue
    },
    set(val) {
      emit('update:modelValue', val)
    }
  })
  watch(() => model, (val) => {setTimeout(() => handleChange(val), 1) }, { deep: true })
  return {
    handleChange,
    model,
    errorMessage,
    valid: meta.valid,
    dirty: meta.dirty
  }
}
