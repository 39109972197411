<template>
  <div>
    <ActionSheetModal ref="actionSheetModal">
      <div class="bg-white dark:bg-gray-800 dark:text-white rounded-md ion-padding h-96 max-h-96 shadow-lg relative">
        <input
          id="query"
          ref="queryInput"
          v-model="query"
          :data-test="name"
          class="dark:bg-gray-700"
          type="text"
          name="query"
        >
        <div class="h-72 max-h-72 mt-4">
          <div class="h-full overflow-y-scroll">
            <div>
              <p
                v-for="item in result"
                :key="item"
                @click="choice(item)"
              >
                {{ item.name }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </Actionsheetmodal>
  </div>
</template>
<script>

import ActionSheetModal from '../../../../components/ActionSheetModal.vue'

export default {
  components: { ActionSheetModal },
  props: {
    name: {
      type: String,
      default: '',
      required: false
    }
  },
  emits: [ 'choice' ],
  data(){
    return {
      query: '',
      items: null,
      sender: ''
    }
  },
  computed: {
    result(){
      let filteredItems = this.items

      if (this.query !== '' && this.query) {
        filteredItems = filteredItems.filter((item) => {
          return item.name
            .toUpperCase()
            .includes(this.query.toUpperCase())
        })
        filteredItems = [ ...new Set(filteredItems) ]
      }
      return filteredItems
    }
  },
  methods: {
    async show(obj){
      this.items = obj.itemsToFilter
      this.sender = obj.sender
      await this.$refs.actionSheetModal.show()
      this.$refs.queryInput.focus()
    },
    choice(item){
      this.$refs.actionSheetModal.hide()
      this.$emit('choice', { sender: this.sender, result: item })
    }
  }
}
</script>
