import { Plugins, StatusBarStyle } from '@capacitor/core'
import statusBarErrorResolver from '@/utilities/statusBarErrorResolver'

export default async function enumGuard(to, from, next) {
  const { StatusBar } = Plugins
  if (to.meta.statusBar) {
    StatusBar.setBackgroundColor({ color: to.meta.statusBar.backgroundColor }).catch(statusBarErrorResolver)
    StatusBar.setStyle({ style: to.meta.statusBar.statusBarStyle }).catch(statusBarErrorResolver)
  } else {
    StatusBar.setBackgroundColor({ color: '#ffffff' }).catch(statusBarErrorResolver)
    StatusBar.setStyle({ style: StatusBarStyle.Light }).catch(statusBarErrorResolver)
  }
  next()
}

