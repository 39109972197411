<template>
  <div class="flex items-top mb-10">
    <div class="flex-shrink-0">
      <div class="relative">
        <img
          v-if="contract.contractCompany?.logo"
          :src="contract.contractCompany.logo.downloadUrl"
          class="rounded-md shadow-md w-20 h-20 object-contain bg-white"
        >
        <img
          v-if="!contract.contractCompany?.logo"
          src="@/assets/default-logo.jpg"
          class="rounded-md shadow-md w-20 h-20 object-contain object-center bg-white"
        >
        <span class="absolute bg-gray-100 px-1 text-sm rounded-sm text-primary -bottom-10 -right-10">{{ getHumanContractStatus(contract.status) }}</span>
      </div>
    </div>
    <div class="flex-auto pl-6 overflow-hidden">
      <h6 class="mb-0">
        {{ contract.contractKind?.name }}
      </h6>
      <p class="font-bold text-gray-400 mb-0">
        {{ contract.number }}
      </p>
      <p class="text-gray-400 mb-0">
        Beginn: {{ dayjs(contract.startDate).format('DD.MM.YYYY') }}
      </p>
      <p class="text-gray-400 mb-0">
        {{ formatNumber(contract.praemieZahlweiseBrutto) }}{{ formatCurrency(contract.currency) }} {{ getHumanPaymentType(contract.paymentType) }}
      </p>
      <p
        v-if="contract.data && contract.data.RisikoAllgemein"
        class="text-gray-400 mb-0"
      >
        Risiko: {{ contract.data.RisikoAllgemein }}
      </p>
    </div>
  </div>
</template>

<script>
import formatCurrency from '@/utilities/filters/formatCurrency'
import formatNumber from '@/utilities/filters/formatNumber'
import dayjs from 'dayjs'
export default {
  props: {
    contract: {
      type: Object,
      default: undefined
    }
  },
  data() {
    return {
      dayjs
    }
  },
  computed: {
    getHumanPaymentType() {
      return this.$store.getters['enums/humanPaymentType']
    }
  },
  methods: {
    formatCurrency,
    formatNumber,
    getHumanContractStatus(apiContractStatus) {
      return this.$store.getters['enums/humanContractStatus'](apiContractStatus)
    }
  }
}
</script>
