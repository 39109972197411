<template>
  <IonPage>
    <IonContent
      :fullscreen="true"
      class="ion-padding"
    >
      <div class="flex justify-center my-8">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="163.066"
          height="214.506"
          viewBox="0 0 163.066 214.506"
        >
          <g
            id="Gruppe_178"
            data-name="Gruppe 178"
            transform="translate(-112.113 -57.776)"
          >
            <path
              id="Pfad_124"
              data-name="Pfad 124"
              d="M1080.3,542.907c5.742,3.144,15.379,11.21,22.351,10.116,8.954-1.436,7.587-11.62,3.349-16.951-4.443-5.468-12.849-8.886-19.89-7.177C1080.845,530.262,1072.847,538.874,1080.3,542.907Z"
              transform="translate(-897.008 -310.586)"
              fill="#ebf2ff"
            />
            <path
              id="Pfad_125"
              data-name="Pfad 125"
              d="M1064.1,432.308c.342-4.853,3.623-17.088,1.094-21.462-2.6-4.443-6.562-1.572-8,1.709-1.3,3.076-2.529,18.865-1.093,21.463C1058.152,437.571,1063.825,436.614,1064.1,432.308Z"
              transform="translate(-890.105 -272.645)"
              fill="#ebf2ff"
            />
            <g
              id="color_shadow"
              transform="translate(176.842 62.667)"
            >
              <g
                id="Gruppe_172"
                data-name="Gruppe 172"
                transform="translate(0 0)"
              >
                <path
                  id="Pfad_148"
                  data-name="Pfad 148"
                  d="M1091.564,387.521a18.781,18.781,0,0,0,17.362-15.926,17.118,17.118,0,0,0-3.554-12.919,15.629,15.629,0,0,0-7.382-7.45q1.026-17.225,2.051-34.449c.341-5.331,1.572-12.235-4.921-14.217-2.529-.752-5.4-.547-8.066-.683-3.007-.137-6.083-.273-9.09-.479-2.8-.137-4.921,3.008-4.716,5.536.2,3.076,2.733,4.58,5.536,4.716,2.461.137,4.922.273,7.383.342,1.161.068,2.255.137,3.418.137.136,0,.41.068.615.068a13.345,13.345,0,0,0-.137,1.572c-.068,1.709-.205,3.418-.273,5.126q-.307,5.126-.615,10.253c-.411,6.835-.821,13.67-1.231,20.437v.479a16.53,16.53,0,0,0-10.868,7.519,18.178,18.178,0,0,0-4.169,17.225C1074.955,383.078,1083.157,388,1091.564,387.521Zm.684-17.156c.068-.068.068,0,0,0Z"
                  transform="translate(-1072.267 -301.394)"
                  fill="#f07b00"
                  opacity="0.318"
                />
              </g>
            </g>
            <path
              id="Pfad_187"
              data-name="Pfad 187"
              d="M1144.659,564.9a121.7,121.7,0,0,0-60.56,28.092"
              transform="translate(-899.169 -322.121)"
              fill="none"
              stroke="#003188"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-miterlimit="10"
              stroke-width="2"
            />
            <path
              id="Pfad_249"
              data-name="Pfad 249"
              d="M1100.088,448.566a35.039,35.039,0,0,1,5.605-20.369c1.3-1.982,3.486-4.1,5.741-3.281,1.641.547,2.392,2.392,2.871,4.033,1.573,5.81,1.025,11.961,2.256,17.84,1.367,6.357,4.853,12.03,7.724,17.84a98.483,98.483,0,0,1,9.843,49.145"
              transform="translate(-904.221 -277.762)"
              fill="none"
              stroke="#003188"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-miterlimit="10"
              stroke-width="2"
            />
            <path
              id="Pfad_250"
              data-name="Pfad 250"
              d="M1050.376,459.551a185.062,185.062,0,0,1,2.6-35.816c.82-4.785,1.914-9.706,4.99-13.465.751-.957,1.982-1.846,3.144-1.5,1.025.273,1.5,1.367,1.845,2.392a51.317,51.317,0,0,1,2.871,22.009c-.479,4.99-1.641,10.321.547,14.9"
              transform="translate(-888.48 -272.684)"
              fill="none"
              stroke="#003188"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-miterlimit="10"
              stroke-width="2"
            />
            <path
              id="Pfad_251"
              data-name="Pfad 251"
              d="M1085.52,467.1a71.7,71.7,0,0,0-25.154,4.716,7.708,7.708,0,0,0-2.461,1.3,10.492,10.492,0,0,0-1.708,2.187,187.212,187.212,0,0,0-13.4,27"
              transform="translate(-886.098 -291.169)"
              fill="none"
              stroke="#003188"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-miterlimit="10"
              stroke-width="2"
            />
            <path
              id="Pfad_252"
              data-name="Pfad 252"
              d="M1064.75,489.6a235.374,235.374,0,0,0-12.85,25.154"
              transform="translate(-888.978 -298.29)"
              fill="none"
              stroke="#003188"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-miterlimit="10"
              stroke-width="2"
            />
            <path
              id="Pfad_253"
              data-name="Pfad 253"
              d="M1080.2,480.238A44.952,44.952,0,0,1,1097.288,476"
              transform="translate(-897.935 -293.986)"
              fill="none"
              stroke="#003188"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-miterlimit="10"
              stroke-width="2"
            />
            <path
              id="Pfad_254"
              data-name="Pfad 254"
              d="M1072.9,494a234.443,234.443,0,0,0-11.21,24.128c-.888,2.324-1.845,4.716-1.435,7.177.41,2.871,2.461,5.263,4.443,7.382,5.606,6.152,11.21,12.235,16.815,18.387"
              transform="translate(-891.592 -299.683)"
              fill="none"
              stroke="#003188"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-miterlimit="10"
              stroke-width="2"
            />
            <path
              id="Pfad_255"
              data-name="Pfad 255"
              d="M1088.4,489.215q9.535-.615,19.07-.615"
              transform="translate(-900.53 -297.973)"
              fill="none"
              stroke="#003188"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-miterlimit="10"
              stroke-width="2"
            />
            <path
              id="Pfad_256"
              data-name="Pfad 256"
              d="M1090.7,520.445q.82-4.819,1.64-9.638a2.709,2.709,0,0,1,.479-1.3,2.228,2.228,0,0,1,1.3-.547c1.983-.41,3.965-.752,5.947-1.162"
              transform="translate(-901.258 -304.05)"
              fill="none"
              stroke="#003188"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-miterlimit="10"
              stroke-width="2"
            />
            <path
              id="Pfad_257"
              data-name="Pfad 257"
              d="M1109.272,503.6a45.6,45.6,0,0,0-.82,15.516"
              transform="translate(-906.775 -302.721)"
              fill="none"
              stroke="#003188"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-miterlimit="10"
              stroke-width="2"
            />
            <path
              id="Pfad_258"
              data-name="Pfad 258"
              d="M1123.907,493.9c1.777,3.076,1.367,6.972.751,10.526a111.29,111.29,0,0,1-3.759,14.764"
              transform="translate(-910.815 -299.651)"
              fill="none"
              stroke="#003188"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-miterlimit="10"
              stroke-width="2"
            />
            <path
              id="Pfad_259"
              data-name="Pfad 259"
              d="M1079.5,527.625a34,34,0,0,1,39.165,15.174"
              transform="translate(-897.713 -309.863)"
              fill="none"
              stroke="#003188"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-miterlimit="10"
              stroke-width="2"
            />
            <path
              id="Pfad_260"
              data-name="Pfad 260"
              d="M1224.3,307.1a151.273,151.273,0,0,1,24.471-11"
              transform="translate(-978.942 -237.051)"
              fill="none"
              stroke="#003188"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-miterlimit="10"
              stroke-width="2"
            />
            <path
              id="Pfad_261"
              data-name="Pfad 261"
              d="M1233.6,343.217c7.382-1.162,14.832-2.256,22.214-3.418"
              transform="translate(-981.886 -250.881)"
              fill="none"
              stroke="#003188"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-miterlimit="10"
              stroke-width="2"
            />
            <path
              id="Pfad_262"
              data-name="Pfad 262"
              d="M1233.6,379.7c7.45,1.572,14.9,3.486,22.214,5.742"
              transform="translate(-981.886 -263.509)"
              fill="none"
              stroke="#003188"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-miterlimit="10"
              stroke-width="2"
            />
            <path
              id="Pfad_263"
              data-name="Pfad 263"
              d="M973.748,312.255c-8.271-2.734-16.609-5.331-24.948-7.655"
              transform="translate(-830.083 -239.741)"
              fill="none"
              stroke="#003188"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-miterlimit="10"
              stroke-width="2"
            />
            <path
              id="Pfad_264"
              data-name="Pfad 264"
              d="M941.01,355.149h-.41a156.367,156.367,0,0,1,22.625-1.162"
              transform="translate(-827.487 -255.362)"
              fill="none"
              stroke="#003188"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-miterlimit="10"
              stroke-width="2"
            />
            <path
              id="Pfad_265"
              data-name="Pfad 265"
              d="M944.4,395.192a152.389,152.389,0,0,1,21.736-7.792"
              transform="translate(-828.69 -265.946)"
              fill="none"
              stroke="#003188"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-miterlimit="10"
              stroke-width="2"
            />
            <g
              id="color_outline"
              transform="translate(174.903 61.92)"
            >
              <g
                id="Gruppe_175"
                data-name="Gruppe 175"
                transform="translate(0 0)"
              >
                <path
                  id="Pfad_268"
                  data-name="Pfad 268"
                  d="M1086.7,409.6a19.391,19.391,0,1,0-17.157-21.394A19.369,19.369,0,0,0,1086.7,409.6Z"
                  transform="translate(-1069.429 -322.653)"
                  fill="none"
                  stroke="#f07b00"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-miterlimit="10"
                  stroke-width="2"
                />
                <path
                  id="Pfad_269"
                  data-name="Pfad 269"
                  d="M1086,390.091a9.428,9.428,0,1,1,7.04,14.217"
                  transform="translate(-1074.674 -327.276)"
                  fill="none"
                  stroke="#f07b00"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-miterlimit="10"
                  stroke-width="2"
                />
                <g
                  id="Gruppe_174"
                  data-name="Gruppe 174"
                  transform="translate(16.316 3.008)"
                >
                  <line
                    id="Linie_4"
                    data-name="Linie 4"
                    y1="34.039"
                    x2="3.691"
                    transform="translate(0 10.8)"
                    fill="none"
                    stroke="#f07b00"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-miterlimit="10"
                    stroke-width="2"
                  />
                  <line
                    id="Linie_5"
                    data-name="Linie 5"
                    x1="5.331"
                    y2="46.001"
                    transform="translate(10.184)"
                    fill="none"
                    stroke="#f07b00"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-miterlimit="10"
                    stroke-width="2"
                  />
                </g>
                <path
                  id="Pfad_270"
                  data-name="Pfad 270"
                  d="M1091.109,313.765l-16.609-1.777,1.3-11.688,26.793,2.939"
                  transform="translate(-1071.034 -300.3)"
                  fill="none"
                  stroke="#f07b00"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-miterlimit="10"
                  stroke-width="2"
                />
              </g>
            </g>
          </g>
        </svg>
      </div>

      <div class="text-center">
        <h1 class="mb-3">
          Anmelden
        </h1>
        <h5 class="text-gray-400 mb-4 leading-6">
          Melde dich hier mit deinen Zugangsdaten für dein Kundenportal an.
        </h5>
      </div>
      <form @submit.prevent="login">
        <div class="mb-4">
          <div class="mt-1">
            <BasicInput
              id="email"
              v-model="email"
              data-test="signin-email"
              label="Email"
              label-class="block text-sm font-medium text-gray-700 dark:text-gray-500"
              type="email"
              name="email"
              placeholder="beispiel@email.de"
              rule="required"
            />
          </div>
        </div>
        <div class="mb-2">
          <div class="mt-1 flex">
            <BasicInput
              id="password"
              v-model="password"
              data-test="signin-password"
              type="password"
              name="password"
              placeholder="***"
              rule="required"
              label="Passwort"
              class="flex-grow input:rounded-none"
              label-class="block text-sm font-medium text-gray-700 dark:text-gray-500"
              input-class="bg-red-500"
              show-button
            />
          </div>
        </div>
        <!-- <router-link
          :to="{name: 'ForgetPassword'}"
          class="text-primary"
        >
          Passwort vergessen?
        </router-link> -->
        <div class="mt-4">
          <button
            id="submit"
            data-test="signin-submit"
            type="submit"
            class="btn bg-primary text-white w-full"
          >
            Anmelden
          </button>
        </div>
      </form>

      <div class="text-center mt-2 mb-2">
        <a
          class="text-black dark:text-white"
          href="https://account-azure.assfinet.de/Account/ForgotPassword"
        >Passwort vergessen?</a>
      </div>

      <div class="text-center text-gray-400 text-sm mt-8 mb-2">
        v{{ $store.state.version }}
      </div>
    </IonContent>
  </IonPage>
</template>

<script>
import { IonPage, IonContent, loadingController, alertController } from '@ionic/vue'
import BasicInput from '../components/BasicInput.vue'

export default  {
  name: 'Login',
  components: { IonContent, IonPage, BasicInput },
  data() {

    return {
      email: '',
      password: '',
      loginPending: false,
      debug: process.env
    }
  },
  async created() {
    // try authentication with jwt token
    try {
      if (!this.$route.query.skipWelcome) {
        await this.$store.dispatch('auth/authenticate')
        this.$router.push({ name: 'Welcome' })
      }
    } catch (e) {
      if (e.name !== 'NotAuthenticated') {
        console.error(e)
      }
    }
  },
  methods: {
    async login() {
      const loading = await loadingController.create({
        message: 'Login'
      })
      await loading.present()
      try {
        await this.$store.dispatch('auth/authenticate', {
          strategy: 'local',
          email: this.email,
          password: this.password
        })
        await this.$store.dispatch('sync-start/get', {
          contracts: true,
          processes: true
        })
        this.$router.push({ name: 'Welcome' })
      } catch (error) {
        const alert = await alertController.create({
          header: 'Fehler',
          message: 'Ungültige Anmeldedaten',
          buttons: [ 'Okay' ]
        })
        alert.present()
      } finally {
        loading.dismiss()
      }

    }
  }
}
</script>
