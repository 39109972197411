<template>
  <IonPage>
    <IonHeader class="ion-no-border">
      <IonToolbar mode="ios">
        <IonButtons slot="start">
          <IonBackButton
            mode="md"
            class="text-primary dark:text-white"
          />
        </IonButtons>
        <IonTitle>
          Schadenmelden
        </IonTitle>
      </IonToolbar>
    </IonHeader>
    <IonContent class="ion-padding">
      <Form
        v-if="userCanCreateDamageReport"
        @submit="create"
      >
        <h2>
          Schaden melden
        </h2>

        <!-- <button @click.prevent="save">
          Save
        </button> -->

        <damage-report-form
          v-model="damageReport.damage"
          :contract-branch="contractBranch"
        />
        <div class="text-right mb-8">
          <input
            class="btn bg-primary text-white mt-4"
            type="submit"
            value="Einreichen"
          >
        </div>
      </Form>
      <p v-else>
        Die Schadenfunktion ist nicht aktiviert. Bitte wenden Sie sich an Ihren Makler
      </p>
    </IonContent>
  </IonPage>
</template>

<script>
import { IonPage, IonHeader, IonToolbar, IonTitle, IonButtons, IonBackButton, IonContent, alertController, loadingController } from '@ionic/vue'
import { models } from '@/feathers-client'
import DamageReportForm from './components/DamageReportForm.vue'
import  { Form } from 'vee-validate'

const { api: { DamageReport } } = models

export default {
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonBackButton,
    IonContent,
    Form,
    DamageReportForm,
  },
  data() {
    return {
      contract: null,
      contractKind: null,
      damageReport: new DamageReport(),
      loading: null,
      submitPending: false,
      contractBranch: null
    }
  },
  computed: {
    user() {
      return this.$store.getters['auth/user']
    },
    userPermissions() {
      if (!this.user) return []
      return this.user.permissions
    },
    userCanCreateDamageReport() {
      if (this.userPermissions.length === 0) return false
      return this.userPermissions.every(permissionObject => permissionObject.Schadenmeldung === true)
    }
  },
  watch: {
    '$store.state.damageReportSubmitStatus': {
      handler(newVal) {
        if (this.submitPending) {
          this.createDamageReportLoading(newVal).then(() => this.loading.present())
        }
      }
    }
  },
  ionViewWillEnter() {
    this.fetchData()
  },
  methods: {
    // async save() {
    //   localStorage.setItem('damage', JSON.stringify(this.damageReport))
    // },
    async create() {
      this.submitPending = true
      const loading = await loadingController.create({
        message: 'Schaden senden'
      })
      await loading.present()

      try {
        this.damageReport.damage.contractBranch = this.contractBranch
        await this.damageReport.create()
        this.submitPending = false
        const alert = await alertController.create({
          header: 'Erfolgreich',
          message: 'Die Schadenmeldung wurde übermittelt.',
          buttons: [ 'Okay' ]
        })
        alert.present()
        alert.onDidDismiss().then(() => this.$router.replace({ name: 'ContractList' }))
      } catch (error) {
        this.submitPending = false
        const alert = await alertController.create({
          header: 'Fehler',
          message: error.message,
          buttons: [ 'Okay' ]
        })
        alert.present()
      } finally {
        loading.dismiss()
      }
    },
    async fetchData() {
      const loading = await loadingController.create({
        message: 'Lade Daten'
      })
      await loading.present()
      try {
        this.contract = await this.$store.dispatch('contracts/get', this.$route.params.contractId)
        this.contractKind = await this.$store.dispatch('contract-kinds/get', this.contract.contractKindId)
        this.damageReport.contractId = this.contract._id
        this.setBranch()
      } catch (error) {
        const alert = await alertController.create({
          header: 'Fehler',
          message: error.message,
          buttons: [ 'Okay' ]
        })
        alert.present()
      } finally {
        loading.dismiss()
      }
    },
    setBranch() {
      switch (this.contractKind.product) {
      case 'HRV':
      case 'Haftpflicht':
      case 'HPV':
        this.contractBranch = 'Haftpflicht'
        break
      case 'BUV':
      case 'ECV':
      case 'FEU':
      case 'GEB':
      case 'GGV':
      case 'GLS':
      case 'Hausrat & Glas':
      case 'Sammelvertrag Rechtsschutz':
      case 'Sammelvertrag SACH':
      case 'Sammerlvertrag Sach':
      case 'WGV':
        this.contractBranch = 'Sach'
        break
      case 'RSV':
      case 'BOOT':
      case 'Berufsunfähigkeitsversicherung':
      case 'Diverse':
      case 'Feuer Ext. Coverage':
        this.contractBranch = 'Default'
        break
      case 'KFZ':
      case 'KFZ Flotte':
      case 'KFZ-VSV':
        this.contractBranch = 'Car'
        break
      case 'BAU':
      case 'EDV':
      case 'Maschinen':
      case 'Montage/Ear':
        this.contractBranch = 'Bau'
        break
      }
    },
    async createDamageReportLoading(message) {
      if (this.loading) {
        this.loading.dismiss()
      }
      this.loading = await loadingController.create({
        message: message
      })
    }
  }
}
</script>
