<template>
  <div>
    <div class="mt-4">
      <BasicCheckbox
        id="haftpflichtSchaden"
        v-model="localValue.haftpflichtSchaden"
        name="haftpflichtSchaden"
        label="Haftpflicht-Schaden"
      />
    </div>
    <div class="mt-4">
      <BasicCheckbox
        id="kaskoSchaden"
        v-model="localValue.kaskoSchaden"
        label="Kasko-Schaden"
        name="kaskoSchaden"
      />
    </div>
    <basic-input
      id="driverName"
      v-model="localValue.driverName"
      class="mt-4"
      label="Name"
    />
    <div class="mt-4">
      <BasicInput
        id="driverBirthDate"
        v-model="localValue.driverBirthDate"
        type="text"
        name="driverBirthDate"
        label="Geburtsdatum"
      />
    </div>
    <basic-input
      id="driverStreet"
      v-model="localValue.driverStreet"
      class="mt-4"
      label="Straße"
    />
    <basic-input
      id="driverZipCode"
      v-model="localValue.driverZipCode"
      class="mt-4"
      label="Postleitzahl"
    />
    <basic-input
      id="driverTown"
      v-model="localValue.driverTown"
      class="mt-4"
      label="Ort"
    />
    <basic-input
      id="driverLicenseClass"
      v-model="localValue.driverLicenseClass"
      class="mt-4"
      label="Führerscheinklasse"
    />
    <div class="mt-4">
      <BasicInput
        id="driverLicenseDate"
        v-model="localValue.driverLicenseDate"
        type="date"
        name="driverLicenseDate"
        label="Austellungsdatum"
      />
    </div>
    <basic-input
      id="driverLicenseId"
      v-model="localValue.driverLicenseId"
      class="mt-4"
      label="Führerschein-Nr."
    />
    <basic-input
      id="driverLicenseAuthority"
      v-model="localValue.driverLicenseAuthority"
      class="mt-4"
      label="Austellende Behörde"
    />
    <div class="mt-4">
      <BasicCheckbox
        id="driverLicenseValid"
        v-model="localValue.driverLicenseValid"
        name="driverLicenseValid"
        label="gültige Fahrerlaubnis"
      />
    </div>
    <div class="mt-4">
      <BasicCheckbox
        id="driverLicenseWithheld"
        v-model="localValue.driverLicenseWithheld"
        name="driverLicenseWithheld"
        label="Führerschein einbehalten"
      />
    </div>
    <basic-input
      id="licensePlate"
      v-model="localValue.licensePlate"
      class="mt-4"
      label="Kennzeichen"
    />
    <div class="mt-4">
      <BasicInput
        id="registrationDate"
        v-model="localValue.registrationDate"
        type="date"
        name="registrationDate"
        label="Erstzulassung"
      />
    </div>
    <basic-input
      id="type"
      v-model="localValue.type"
      class="mt-4"
      label="Fahrzeugart"
    />
    <basic-input
      id="manufacturer"
      v-model="localValue.manufacturer"
      class="mt-4"
      label="Hersteller/Fahrzeugtyp"
    />
    <basic-input
      id="chassisNumber"
      v-model="localValue.chassisNumber"
      class="mt-4"
      label="Fahrgestell-Nr"
    />
    <div class="mt-4">
      <BasicCheckbox
        id="itemsInsuredElsewhere"
        v-model="localValue.itemsInsuredElsewhere"
        label="Betroffene Sachen sind anderweitig versichert?"
        name="itemsInsuredElsewhere"
      />
    </div>
    <!-- <div
      v-if="localValue.itemsInsuredElsewhere"
      class="mt-4"
    >
      v-if="data.pdf.ja_9"
      <label for="otherInsurance">Wo?</label>
      <input
        id="otherInsurance"
        v-model="localValue.otherInsurance"
        type="text"
        name="otherInsurance"
      >
      [(ngModel)]="data.pdf['Wo Versichert']"
    </div> -->
    <div class="mt-4">
      <BasicCheckbox
        id="leasedOrBankFinanced"
        v-model="localValue.leasedOrBankFinanced"
        name="leasedOrBankFinanced"
        label="Geleast oder bankfinanziert?"
      />
    </div>
    <basic-input
      v-if="localValue.leasedOrBankFinanced"
      id="institute"
      v-model="localValue.institute"
      class="mt-4"
      label="Wo?"
    />
    <div class="mt-4">
      <BasicCheckbox
        id="connectedToTrailer"
        v-model="localValue.connectedToTrailer"
        name="connectedToTrailer"
        label="Anhänger"
      />
    </div>
    <basic-input
      v-if="localValue.connectedToTrailer"
      id="trailerLicensePlate"
      v-model="localValue.trailerLicensePlate"
      class="mt-4"
      label="Kennzeichen"
    />
    <basic-input
      v-if="localValue.connectedToTrailer"
      id="trailerInsuranceContractId"
      v-model="localValue.trailerInsuranceContractId"
      class="mt-4"
      label="Vertragsnummer"
    />
    <basic-input
      v-if="localValue.connectedToTrailer"
      id="trailerInsuranceCompany"
      v-model="localValue.trailerInsuranceCompany"
      class="mt-4"
      label="Versicherer"
    />
    <div class="mt-4">
      <BasicInput
        id="accidentCause"
        v-model="localValue.accidentCause"
        rules="required"
        as="select"
        label="Unfallursache"
        name="accidentCause"
      >
        <option
          value="0"
          disabled
          selected
        >
          bitte wählen
        </option>
        <option value="1">
          Auffahrunfall
        </option>
        <option value="2">
          Rangieren/Parken
        </option>
        <option value="3">
          Vorfahrt verletzt
        </option>
        <option value="4">
          Sturm/Hagel
        </option>
        <option value="5">
          Brand
        </option>
        <option value="6">
          Glas
        </option>
        <option value="7">
          Tierwild
        </option>
        <option value="8">
          Tierbiss
        </option>
        <option value="9">
          Tier Vandalismus
        </option>
        <option value="10">
          Teildiebstahl
        </option>
        <option value="11">
          Totaldiebstahl
        </option>
        <option value="12">
          Sonstiges
        </option>
      </BasicInput>
    </div>
    <h5 v-if="localValue.accidentCause === '11'">
      <i class="fad fa-exclamation-triangle" />
      Bei Totaldiebstahl ist ein gesondertes Formular auszufüllen. Bitte sprechen Sie uns hierzu an.
    </h5>
    <div class="mt-4">
      <BasicInput
        id="accidentResponsibility"
        v-model="localValue.accidentResponsibility"
        name="accidentResponsibility"
        label="Haben Sie den Unfall verursacht?"
        rules="required"
        as="select"
      >
        <option
          value="0"
          disabled
          selected
        >
          bitte wählen
        </option>
        <option value="1">
          Ja
        </option>
        <option value="2">
          Nein
        </option>
        <option value="3">
          teilweise
        </option>
      </BasicInput>
    </div>
    <div class="mt-4">
      <BasicCheckbox
        id="recordedByPolice"
        v-model="localValue.recordedByPolice"
        name="recordedByPolice"
        label="Polizeiliche Aufnahme?"
      />
    </div>
    <basic-input
      v-if="localValue.recordedByPolice"
      id="policeStation"
      v-model="localValue.policeStation"
      class="mt-4"
      label="Polizeidienststelle"
    />
    <basic-input
      v-if="localValue.recordedByPolice"
      id="diaryNumber"
      v-model="localValue.diaryNumber"
      class="mt-4"
      label="Aktenzeichen"
    />
    <div class="mt-4">
      <BasicCheckbox
        id="offenderIdentified"
        v-model="localValue.offenderIdentified"
        name="offenderIdentified"
        label="Täter ermittelt?"
      />
    </div>
    <basic-input
      v-if="localValue.offenderIdentified"
      id="offenderData"
      v-model="localValue.offenderData"
      class="mt-4"
      label="Name und Anschrift des Täters"
    />
    <div class="mt-4">
      <BasicCheckbox
        id="driverDrunk"
        v-model="localValue.driverDrunk"
        name="driverDrunk"
        label="Fahrer alkoholisiert?"
      />
    </div>
    <div class="mt-4">
      <BasicCheckbox
        id="opponentDrunk"
        v-model="localValue.opponentDrunk"
        name="opponentDrunk"
        label="Gegner alkoholisiert?"
      />
    </div>
    <div class="mt-4">
      <BasicCheckbox
        id="driverBloodtest"
        v-model="localValue.driverBloodtest"
        name="driverBloodtest"
        label="Fahrer Blutprobe?"
      />
    </div>
    <div class="mt-4">
      <BasicCheckbox
        id="opponentBloodtest"
        v-model="localValue.opponentBloodtest"
        label="Gegner Blutprobe?"
        name="opponentBloodtest"
      />
    </div>
    <basic-input
      id="itemsStolen"
      v-model="localValue.itemsStolen"
      class="mt-4"
      label="Welche Teile wurden gestohlen bzw. beschädigt?"
    />
    <basic-input
      id="howSecured"
      v-model="localValue.howSecured"
      class="mt-4"
      label="Wie war das Fahrzeug und die entwendeten Teile zum Schadenzeitpunkt gesichert?"
    />
    <basic-input
      id="keyLocation"
      v-model="localValue.keyLocation"
      class="mt-4"
      label="Wo befanden sich die Schlüssel?"
    />
    <basic-input
      id="ClosedDoorsWindows"
      v-model="localValue.ClosedDoorsWindows"
      class="mt-4"
      label="Waren alle Scheiben und Türen geschlossen?"
    />
  </div>
</template>
<script>
import { reactive, toRef, watch } from 'vue'

import BasicInput from '../../../components/BasicInput.vue'
import BasicCheckbox from '../../../components/BasicCheckbox.vue'

export default {
  components: { BasicInput, BasicCheckbox },
  props: {
    modelValue: {
      type: Object,
      default: () => {}
    }
  },
  emits: [ 'update:modelValue' ],
  setup(props, { emit }) {
    const modelValue = toRef(props, 'modelValue')
    const localValue = reactive({ ...modelValue.value })

    watch(() => localValue, (val) => emit('update:modelValue', val), { deep: true })

    return {
      localValue
    }
  }
}
</script>
