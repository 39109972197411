import feathersClient, { makeServicePlugin, BaseModel } from '../../feathers-client'

class SyncStart extends BaseModel {
  constructor(data, options) {
    super(data, options)
  }

  static instanceDefaults() {
    return {}
  }
}

SyncStart.modelName = 'SyncStart'

const servicePath = 'sync-start'
const servicePlugin = makeServicePlugin({
  Model: SyncStart,
  service: feathersClient.service(servicePath),
  servicePath,
  handleEvents: {
    updated: (_item, { model }) => {
      model.store.commit('setContractsUpdating', false)
    }
  }
})

feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
})

export default servicePlugin
