<template>
  <div>
    <transition name="slide-fade">
      <p
        v-if="errorMessage"
        class="text-red-600"
      >
        {{ errorMessage }}
      </p>
    </transition>
    <!-- Das Feld {{ fieldName }} ist ein Pflichtfeld. -->
  </div>
</template>

<script>


export default {
  components: {
  },
  props: {
    errorMessage: {
      type: String,
      default: ''
    },
  }
}
</script>


<style lang="scss" scoped>
  .shake-enter-active {
    animation: shake 0.5s;
  }

  @keyframes shake {
    8%, 41% {
      -webkit-transform: translateX(-10px);
    }

    25%, 58% {
      -webkit-transform: translateX(10px);
    }

    75% {
      -webkit-transform: translateX(-5px);
    }

    92% {
      -webkit-transform: translateX(5px);
    }

    0%, 100% {
      -webkit-transform: translateX(0);
    }
  }
</style>
