<template>
  <div>
    <h4 class="my-2">
      Bankverbindung
    </h4>
    <!-- <div class="mt-4">
      <input
        id="dataFromContract"
        type="checkbox"
        name="dataFromContract"
      >
      <label for="dataFromContract">Aus dem Vertrag übernehmen?</label>
      <ion-toggle
        name="dataFromContract"
        (ionChange)="bankFromContact()"
        [(ngModel)]="data.pdf.dataFromContract"
      />
    </div> -->
    <basic-input
      id="accountHolder"
      v-model="localValue.accountHolder"
      class="mt-4"
      label="Name"
    />
    <basic-input
      id="bankName"
      v-model="localValue.bankName"
      class="mt-4"
      label="Kreditinstitut"
    />
    <basic-input
      id="iban"
      v-model="localValue.iban"
      class="mt-4"
      label="IBAN"
    />
    <basic-input
      id="bic"
      v-model="localValue.bic"
      class="mt-4"
      label="BIC"
    />
  </div>
</template>
<script>
import { reactive, toRef, watch } from 'vue'

import BasicInput from '../../../components/BasicInput.vue'

export default {
  components: { BasicInput },
  props: {
    modelValue: {
      type: Object,
      default: () => {}
    }
  },
  emits: [ 'update:modelValue' ],
  setup(props, { emit }) {
    const modelValue = toRef(props, 'modelValue')
    const localValue = reactive({ ...modelValue.value })

    watch(() => localValue, (val) => emit('update:modelValue', val), { deep: true })

    return {
      localValue
    }
  }
}
</script>
