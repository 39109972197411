import store from '@/store/'

let fetchedEnums = false

// Fetch data if store is empty
export default async function preloadGuard(to, from, next) {
  await preloadContracts()
  await preloadProcesses()
  await preloadEnums()

  next()
}

async function preloadContracts() {
  if (store.getters['contracts/list'].length > 0 || !store.getters['auth/isAuthenticated']) return

  await store.dispatch('contracts/find', { query: { $client: { paginate: false } } })
}

async function preloadProcesses() {
  if (store.getters['processes/list'].length > 0 || !store.getters['auth/isAuthenticated']) return

  await store.dispatch('processes/find', { query: { $client: { paginate: false } } })
}

async function preloadEnums() {
  if (!fetchedEnums) {
    try {
      await store.dispatch('enums/find')
      fetchedEnums = true
    } catch (err) {
      console.error('error fetching enums')
      console.error(err)
    }
  }
}