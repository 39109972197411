<template>
  <div>
    <label
      v-if="type !== 'checkbox'"
      :for="id"
      :class="labelClass"
    >{{ label }}
      <small>
        {{ small }}
      </small>
    </label>
    <div class="flex">
      <Field
        v-if="setType"
        :id="id"
        v-slot="args"
        :class="{ 'rounded-r-none':showButton, inputClass }"
        :value="modelValue"
        :model-value="modelValue"
        :type="localType"
        :as="as"
        :name="id"
        :label="label"
        :placeholder="placeholder"
        :rules="rules"
        lang="de-DE"
        :disabled="disabled"
        @input="updateModel"
      >
        <slot v-bind="args" />
      </Field>
      <Field
        v-else
        :id="id"
        v-slot="args"
        :class="inputClass"
        :model-value="modelValue"
        :as="as"
        :name="id"
        lang="de-DE"
        :label="label"
        :rules="rules"
        @input="(event) => $emit('update:modelValue', event.target.value)"
      >
        <slot v-bind="args" />
      </Field>
      <button
        v-if="showButton"
        type="button"
        class="btn btn-primary rounded-l-none dark:bg-gray-800"
        @click="toggleType"
      >
        <FontAwesomeIcon
          v-if="localType==='password'"
          :icon="faEye"
        />
        <FontAwesomeIcon
          v-else
          :icon="faEyeSlash"
        />
      </button>
      <label
        v-if="type === 'checkbox'"
        :for="id"
      >{{ label }} </label>
    </div>
    <ErrorMessage
      v-slot="{ message }"
      :name="id"
    >
      <!-- <p
        v-if="Object.keys(errors).length"
        id="email-error"
        class="mt-2 text-sm text-red-600"
      > -->
      <p class="text-red-600">
        {{ message }}
      </p>
    </ErrorMessage>
  </div>
</template>
<script>
import { Field, ErrorMessage } from 'vee-validate'

import { faEye, faEyeSlash } from '@fortawesome/pro-duotone-svg-icons'

export default {
  components: {
    Field,
    ErrorMessage,
  },
  props: {
    id: {
      required: true,
      type: String,
    },
    label: {
      required: true,
      type: String,
    },
    inputClass: {
      required: false,
      type: String,
      default: null,
    },
    labelClass: {
      required: false,
      type: String,
      default: null,
    },
    small: {
      required: false,
      type: String,
      default: null,
    },
    placeholder: {
      required: false,
      type: String,
      default: null,
    },
    rules: {
      required: false,
      type: [ Object, String, Function ],
      default: () => {
        return {}
      },
    },
    type: {
      required: false,
      type: String,
      default: 'text',
    },
    as: {
      required: false,
      type: String,
      default: 'input',
    },
    disabled: {
      required: false,
      type: Boolean,
    },
    modelValue: {
      required: false,
      type: [ String, Number, Boolean ],
      default: '',
    },
    showButton: {
      type: Boolean,
      default: false
    }
  },
  emits: {
    'update:modelValue': {
      type: String,
    },
  },
  data(){
    return {
      localType: this.type,
      faEye,
      faEyeSlash
    }
  },
  computed: {
    setType() {
      return this.as !== 'select' && this.as !== 'textarea'
    },
  },
  methods: {
    updateModel(event) {
      if (this.type === 'checkbox') {
        this.$emit('update:modelValue', event.target.checked)
      } else {
        this.$emit('update:modelValue', event.target.value)
      }
    },
    isRequired(value) {
      if (value && value.trim()) {
        return true
      }

      return 'This is required'
    },
    toggleType(){
      if (this.localType === 'password'){
        this.localType = 'text'
      } else {
        this.localType = 'password'
      }
    }
  },
}
</script>
