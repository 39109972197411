<template>
  <label
    for="file-upload"
    class="cursor-pointer mt-4"
  >
    <div class="w-full flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded">
      <div class="space-y-1 text-center">
        <FontAwesomeIcon
          size="2x"
          class="text-primary dark:text-white"
          :icon="faFilePlus"
        />
        <div class="text-center">
          <p class="text-gray-600 mb-1 dark:text-gray-400">
            Anhang hochladen
          </p>
          <!-- <p class="text-xs text-gray-500 mb-0">
            PNG, JPG, GIF up to 10MB TODO
          </p> -->
        </div>
        <input
          id="file-upload"
          name="file-upload"
          type="file"
          class="sr-only"
          multiple
          @change="$emit('upload', $event)"
        >
      </div>
    </div>
  </label>
</template>
<script>
import { faFilePlus } from '@fortawesome/pro-duotone-svg-icons'

export default {
  emits: {
    upload: {
      type: String
    }
  },
  data() {
    return {
      faFilePlus
    }
  }

}
</script>
